import './Command.css';

function Command({ id, disabled, onClick, children }) {

    const onButtonClick = (ev) => {
        ev.preventDefault();
        onClick();
    };

    if (disabled)
        return (
            <button id={id} className='action' disabled={true}>
                {children}
            </button>
        );
    else
        return (
            <button id={id} className='action' onClick={onButtonClick}>
                {children}
            </button>
        );
}

export default Command;
