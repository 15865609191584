import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

function PasswordInput({ id, name, control, className, rules, autoFocus }) {

    return (
        <Controller
            render={({ onChange, value, name }) => (
                <input
                    type='password'
                    id={id}
                    name={name}
                    className={className + ' form-input'}
                    value={value}
                    onChange={onChange}
                    autoFocus={autoFocus}/>
            )}
            name={name}
            control={control}
            rules={rules}/>
    )
}

PasswordInput.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    control: PropTypes.object.isRequired,
    className: PropTypes.string,
    rules: PropTypes.object,
    autoFocus: PropTypes.bool
};

PasswordInput.defaultProps = {
    className: '',
    rules: { required: true },
    autoFocus: false
};

export default PasswordInput;
