import axios from 'axios';
import { useForm } from 'react-hook-form';
import { useModal } from '../../components/Modal';
import Error from '../../components/Error';
import './UserPanel.css';

function UserPanel() {

    const { register, handleSubmit, errors, reset } = useForm({});
    const modal = useModal();

    const onSubmit = form => {
        const data = {
            current_password: form.current_password,
            new_password: form.new_password,
            verify: form.verify
        };
        axios.post(`/api/users/self/password`, data)
            .then(() => modal({ title: 'Password', message: 'Password actualizada' }))
            .then(() => reset())
            .catch(error => {
                if (error.response && error.response.status === 400)
                    modal({ title: 'Ocurrió un error', message: error.response.data.errors[0] });
                else
                    modal({ title: 'Ocurrió un error', message: error.message });
                }
            );
    };

    return (
        <div id='user-settings-container'>
            <div id='user-settings-title-container' className='form-grid'>
                <h2 id='user-settings-title'>Panel de usuario</h2>
            </div>
            <h3 id='password-title'>
                Password
            </h3>
            <div id='password-details-container'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <section id='password-details' className='form-grid'>
                        <label htmlFor='current_password'>Password actual</label>
                        <input
                            type='password'
                            className='form-input'
                            name='current_password'
                            id='current_password'
                            ref={register({ required: true })}/>
                        {errors.current_password && <Error className='error-current_password' error='⛔️' description='Campo requerido'/>}
                        <label htmlFor='new_password'>Nueva password</label>
                        <input
                            type='password'
                            className='form-input'
                            name='new_password'
                            id='new_password'
                            ref={register({ required: true })}/>
                        {errors.new_password && <Error className='error-new_password' error='⛔️' description='Campo requerido'/>}
                        <label htmlFor='verify'>Verificación</label>
                        <input
                            type='password'
                            className='form-input'
                            name='verify'
                            id='verify'
                            ref={register({ required: true })}/>
                        {errors.verify && <Error className='error-verify' error='⛔️' description='Campo requerido'/>}
                        <div className='buttons'>
                            <button className='change-password'>Cambiar password</button>
                        </div>
                    </section>
                </form>
            </div>
        </div>
    );
}

export default UserPanel;
