import "./Subscriptions.css";
import axios from "axios";
import { useModal } from "../../components/Modal";
import Command from "../../components/Command";
import { Subscription } from "./Subscription";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import handleError from "../../misc/ErrorHandling";
import { useContext, useState } from "react";
import { formatDate } from "../../misc/Values";
import { periodMapping, RESOURCE_SUBSCRIPTIONS } from "../../misc/ReferenceData";
import { SessionContext } from "../../components/Session";
import { useNoCacheAxios } from '../../misc/Net';
import AmountDisplay from '../../components/form/AmountDisplay';
import { ClientSelector } from '../../components/form/ClientSelect';

function Subscriptions() {

    const session = useContext(SessionContext);

    const { canWrite } = session;

    const readOnly = !canWrite(RESOURCE_SUBSCRIPTIONS);

    const [clientId, setClientId] = useState("");

    const modal = useModal();

    const [orderField, setOrderField] = useState("client");

    const [orderDir, setOrderDir] = useState("asc");

    const [{ data: subscriptions, loading }, getSubscriptions] =
        useNoCacheAxios(`/api/subscriptions?client_id=${clientId}&order=${orderField}-${orderDir}`);

    const onClientSelect = (selectedClient) =>
        setClientId(selectedClient.id);

    const onSubscriptionSaved = () =>
        getSubscriptions()
            .catch(error => handleError("Abonos", error, modal));

    const onGetSubscriptions = () =>
        getSubscriptions()
            .catch(error => handleError("Abonos", error, modal));

    const onNewSubscriptionClick = () =>
        modal({
            title: "Alta abono",
            style: "dialog",
            component: onClose =>
                <Subscription closeDialog={onClose}
                              modal={modal}
                              onSubscriptionSaved={onSubscriptionSaved}
                              session={session}/>
        });

    const onSubscriptionSelect = (subscriptionId) => () =>
        modal({
            title: readOnly ? "Detalle abono" : "Modificación abono",
            style: "dialog",
            component: onClose =>
                <Subscription subscriptionId={subscriptionId}
                              closeDialog={onClose}
                              modal={modal}
                              onSubscriptionSaved={onSubscriptionSaved}
                              session={session}/>
        });

    const onSubscriptionDelete = subscriptionId => () =>
        modal({ title: "Abono", message: "Confirme que desea eliminar el abono", confirm: true })
            .then(() => axios.delete(`/api/subscriptions/${subscriptionId}`))
            .then(() => getSubscriptions())
            .catch(error => handleError("Eliminación de abono", error, modal));

    const orderClass = (field) =>
        field === orderField ? "ordered" : "";

    const orderSign = (field) =>
        field === orderField ? (orderDir === "asc" ? "↑" : "↓") : "";

    const order = (field) => () => {
        if (field === orderField) {
            setOrderDir(orderDir === "asc" ? "desc" : "asc");
        } else {
            setOrderField(field);
            setOrderDir("asc");
        }
    }

    let rows;
    if (loading || !subscriptions)
        rows = (
            <div className="table-row">
                <span className="table-cell-whole-row">Cargando...</span>
            </div>
        );
    else if (subscriptions.data.length === 0)
        rows = (
            <div className="table-row">
                <span className="table-cell-whole-row">No hay abonos</span>
            </div>
        );
    else
        rows = subscriptions.data.map(subscription => {
            return (
                <div key={subscription.id} className="table-row">
                    <span className="table-cell clickable" onClick={onSubscriptionSelect(subscription.id)}>{subscription.client_name}</span>
                    <span className="table-cell clickable" onClick={onSubscriptionSelect(subscription.id)}>{subscription.description}</span>
                    <span className="table-cell clickable" onClick={onSubscriptionSelect(subscription.id)}>{periodMapping[subscription.invoice_period]}</span>
                    <span className="table-cell clickable" onClick={onSubscriptionSelect(subscription.id)}>{formatDate(subscription.start_date)}</span>
                    <span className="table-cell clickable amount" onClick={onSubscriptionSelect(subscription.id)}>
                        <AmountDisplay amount={subscription.fee} prefix="$ "/>
                    </span>
                    <span className="table-cell clickable" onClick={onSubscriptionSelect(subscription.id)}>
                        {subscription._updated
                            ? <abbr title={subscription._updated}>{subscription._updated.split(' ')[0]}</abbr>
                            : 'N/A'
                        }
                    </span>
                    <span className="table-cell">
                        <Command onClick={onSubscriptionDelete(subscription.id)} disabled={readOnly}>
                            <FontAwesomeIcon icon="trash-alt"/>
                        </Command>
                    </span>
                </div>
            );
        });

    return (
        <>
            <div id="subscriptions-container">
                <div id="subscriptions-title-container" className="form-grid">
                    <h2 id="subscriptions-title">ABM Abonos</h2>
                </div>
                <div id="client-select-container" className="form-grid">
                    <label htmlFor="client-selector">Cliente</label>
                    <ClientSelector id="client-selector" defaultLabel="Todos los clientes" onSelect={onClientSelect}/>
                </div>
                <h3 id="active-subscriptions-title">
                    Abonos Activos
                    <Command onClick={onGetSubscriptions}><FontAwesomeIcon icon="sync-alt"/></Command>
                </h3>
                <div id="subscriptions">
                    <div className="table-row">
                        <span className={"table-header clickable " + orderClass("client")}
                              onClick={order("client")}>
                            Cliente {orderSign("client")}
                        </span>
                        <span className={"table-header clickable " + orderClass("description")}
                              onClick={order("description")}>
                            Concepto {orderSign("description")}
                        </span>
                        <span className={"table-header clickable " + orderClass("period")}
                              onClick={order("period")}>
                            Ciclo {orderSign("period")}
                        </span>
                        <span className={"table-header clickable " + orderClass("start")}
                              onClick={order("start")}>
                            Alta {orderSign("start")}
                        </span>
                        <span className={"table-header clickable " + orderClass("fee")}
                              onClick={order("fee")}>
                            Importe {orderSign("fee")}
                        </span>
                        <span className={"table-header clickable " + orderClass("updated")}
                              onClick={order("updated")}>
                            Últ.Mod. {orderSign("updated")}
                        </span>
                        <span className="table-header">Eliminar</span>
                    </div>
                    {rows}
                </div>
            </div>

            {!readOnly &&
            <div className="operations">
                <button className="new-hosting-plan" onClick={onNewSubscriptionClick}>Nuevo Abono</button>
            </div>
            }
        </>
    );
}

export default Subscriptions;
