import "./App.css";
import { useEffect, useState } from "react";
import Menu from "./components/Menu";
import Home from "./modules/Home";
import Clients from "./modules/clients/Clients";
import Subscriptions from "./modules/subscriptions/Subscriptions";
import Hostings from "./modules/hostings/Hostings";
import HostingPlans from "./modules/hosting-plans/HostingPlans";
import Work from "./modules/works/Work";
import Works from "./modules/works/Works";
import AdditionalItem from "./modules/additional-items/AdditionalItem";
import AdditionalItems from "./modules/additional-items/AdditionalItems";
import MonthlyInvoices from "./modules/invoices/MonthlyInvoices";
import ImmediateInvoices from "./modules/invoices/ImmediateInvoices";
import Session from "./components/Session";
import UserPanel from "./modules/security/UserPanel";
import Settings from "./modules/settings/Settings"
import { ModalProvider } from "./components/Modal";
import { library } from "@fortawesome/fontawesome-svg-core"
import {
    faTrashAlt,
    faSyncAlt,
    faCogs,
    faSignOutAlt,
    faUserCog,
    faUser,
    faArrowDown,
    faFileInvoiceDollar,
    faHourglassHalf,
    faHeartBroken,
    faDownload,
    faCopy,
    faListAlt,
    faCaretSquareDown,
    faEye,
    faPlusCircle
} from "@fortawesome/free-solid-svg-icons"
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from "date-fns/locale/es";

function App() {

    const [module, setModule] = useState("home");
    useEffect(() => {
        library.add(
            faTrashAlt,
            faSyncAlt,
            faCogs,
            faSignOutAlt,
            faUserCog,
            faUser,
            faArrowDown,
            faFileInvoiceDollar,
            faHourglassHalf,
            faHeartBroken,
            faDownload,
            faCopy,
            faListAlt,
            faCaretSquareDown,
            faEye,
            faPlusCircle
        );
        registerLocale("es", es);
        setDefaultLocale("es");
    }, []);

    let moduleComponent;
    switch (module) {
        case "home":
            moduleComponent = <Home/>;
            break;
        case "clients":
            moduleComponent = <Clients/>;
            break;
        case "subscriptions":
            moduleComponent = <Subscriptions/>;
            break;
        case "hostings":
            moduleComponent = <Hostings/>;
            break;
        case "hosting-plans":
            moduleComponent = <HostingPlans/>;
            break;
        case "work":
            moduleComponent = <Work/>;
            break;
        case "works":
            moduleComponent = <Works/>;
            break;
        case "additional-item":
            moduleComponent = <AdditionalItem/>;
            break;
        case "additional-items":
            moduleComponent = <AdditionalItems/>;
            break;
        case "monthly-invoices":
            moduleComponent = <MonthlyInvoices/>;
            break;
        case "immediate-invoices":
            moduleComponent = <ImmediateInvoices/>;
            break;
        case "settings":
            moduleComponent = <Settings/>;
            break;
        case "password":
            moduleComponent = <UserPanel/>;
            break;
        default:
            moduleComponent = (
                <div id="system-unavailable">
                    <p>Módulo no reconocido: {module}</p>
                </div>
            );
    }

    return (
        <ModalProvider>
            <div className="App">
                <Session setModule={setModule}>
                    <Menu onClick={setModule}/>
                    {moduleComponent}
                </Session>
            </div>
        </ModalProvider>
    );
}

export default App;
