import { MODAL_CANCELED } from '../components/Modal';

export default function handleError(operation, error, modal) {
    if (error === MODAL_CANCELED)
        return;
    if (error.response && error.response.status >= 400 && error.response.status < 500)
        return modal({ title: operation, message: extractErrorMessage(error) });
    else
        return modal({ title: `${operation}: Error inesperado`, message: error.message ?? error.toString()});
}

function extractErrorMessage(error) {
    try {
        return error.response.data.errors[0];
    } catch (e) {
        return error.message ?? error.toString();
    }
}
