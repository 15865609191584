import "./MonthlyInvoices.css";
import "./Common.css";
import { useModal } from "../../components/Modal";
import Command from "../../components/Command";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import handleError from "../../misc/ErrorHandling";
import { useContext, useState } from "react";
import { ClientSelector } from "../../components/form/ClientSelect";
import { SessionContext } from "../../components/Session";
import { useNoCacheAxios } from "../../misc/Net";
import { PeriodSelect } from "../../components/form/PeriodSelect";
import { initials, isOldPeriod, lastPeriod } from "../../misc/Values";
import { ClientMonthlyCharges } from './ClientMonthyCharges';
import AmountDisplay from '../../components/form/AmountDisplay';

function MonthlyInvoices() {

    let session = useContext(SessionContext);

    const [clientId, setClientId] = useState("");

    const [period, setPeriod] = useState(lastPeriod);

    const [invoiceStateFilter, setInvoiceStateFilter] = useState("ALL");

    const modal = useModal();

    const [{ data: invoices, loading }, getInvoices] =
        useNoCacheAxios(`/api/charges/monthly?month=${period.month}&year=${period.year}&client_id=${clientId}`);

    const onClientSelect = (selectedClient) =>
        setClientId(selectedClient.id);

    const onInvoiceSaved = () =>
        getInvoices()
            .catch(error => handleError("Facturación", error, modal));

    const onGetInvoices = () =>
        getInvoices()
            .catch(error => handleError("Facturación", error, modal));

    const onInvoiceSelect = (legalEntityId, clientId) => () =>
        modal({
            title: "Detalle factura pendiente",
            style: "dialog",
            component: onClose =>
                <ClientMonthlyCharges
                    legalEntityId={legalEntityId}
                     clientId={clientId}
                     month={period.month}
                     year={period.year}
                     closeDialog={onClose}
                     modal={modal}
                     onInvoiceSaved={onInvoiceSaved}
                     session={session}/>
        });

    let rows;
    if (loading || !invoices)
        rows = (
            <div className="table-row">
                <span className="table-cell-whole-row">Cargando...</span>
            </div>
        );
    else if (invoices.data.charge_summaries.length === 0)
        rows = (
            <div className="table-row">
                <span className="table-cell-whole-row">
                    {isOldPeriod(period)
                        ? "No hubo cargos a facturar"
                        : "No hay cargos a facturar"
                    }
                </span>
            </div>
        );
    else
        rows = Object.keys(invoices.data.charge_summaries)
            .sort()
            .filter(key =>
                invoiceStateFilter === "ALL" ||
                (invoiceStateFilter === "INVOICED" && invoices.data.charge_summaries[key].status === "invoiced") ||
                (invoiceStateFilter === "PENDING" && invoices.data.charge_summaries[key].status !== "invoiced")
            )
            .map(key => {
                let charge_summary = invoices.data.charge_summaries[key];
                const onClick = onInvoiceSelect(charge_summary.legal_entity_id, charge_summary.client_id);
                return (
                    <div key={key} className="table-row">
                        <span className="table-cell invoice-issuer">
                            {charge_summary.legal_entity_name &&
                            <>
                                <span>{initials(charge_summary.legal_entity_name)}</span>
                                &nbsp;-&nbsp;
                                <span className="cuit">{charge_summary.legal_entity_cuit}</span>
                            </>
                            }
                        </span>
                        <span className="table-cell clickable client" onClick={onClick}>{charge_summary.client_name}</span>
                        <span className="table-cell clickable amounts" onClick={onClick}>
                            <AmountDisplay amount={charge_summary.subscription} defaultValue="NO"/>
                        </span>
                        <span className="table-cell clickable amounts" onClick={onClick}>
                            <AmountDisplay amount={charge_summary.hosting} defaultValue="NO"/>
                        </span>
                        <span className="table-cell clickable amounts" onClick={onClick}>
                            <AmountDisplay amount={charge_summary.additional_item} defaultValue="NO"/>
                        </span>
                        <span className="table-cell clickable amounts" onClick={onClick}>
                            <AmountDisplay amount={charge_summary.work} defaultValue="NO"/>
                        </span>
                        <span className="table-cell clickable amounts" onClick={onClick}>
                            <AmountDisplay amount={charge_summary.client_total} defaultValue="NO"/>
                        </span>
                        <span className="table-cell clickable" onClick={onClick}>
                            {charge_summary.status === "pending"
                                ? <FontAwesomeIcon icon="hourglass-half" title="Pendiente de facturar"/>
                                : charge_summary.status === "invoiced"
                                    ? <FontAwesomeIcon icon="file-invoice-dollar" title="Facturado"/>
                                    : <FontAwesomeIcon icon="heart-broken" title="Hay ítems sin facturar 💸"/>
                            }
                        </span>
                    </div>
                );
            });

    if (rows && rows.length === 0) {
        rows = (
            <div className="table-row">
                <span className="table-cell-whole-row">
                    Ningún registro cumple los criterios de búsqueda
                </span>
            </div>
        );
    }

    return (
        <div id="invoices-container">
            <div id="invoices-title-container" className="form-grid">
                <h2 id="invoices-title">
                    Planilla de Facturación Mensual
                    <Command onClick={onGetInvoices}><FontAwesomeIcon icon="sync-alt"/></Command>
                </h2>
            </div>
            <div id="filters-container" className="form-grid">
                <label className="form-label" htmlFor="client-selector">Cliente</label>
                <ClientSelector id="client-selector" defaultLabel="Todos los clientes" onSelect={onClientSelect}/>
                <span/>
                <label className="form-label" htmlFor="month">Período</label>
                <PeriodSelect
                    id="period"
                    period={period}
                    defaultPeriod={lastPeriod}
                    defaultPeriodLabel="Último período"
                    onSelect={setPeriod}/>
                <span/>
                <label className="form-label" htmlFor="status">Estado</label>
                <div className="options-status options">
                    <label className="radio-label">
                        Todas
                        <input
                            type="radio"
                            className="form-input"
                            name="status"
                            value="ALL"
                            checked={invoiceStateFilter === "ALL"}
                            onChange={ev => setInvoiceStateFilter(ev.target.value)}/>
                        <span/>
                    </label>
                    <label className="radio-label">
                        Facturadas
                        <input
                            type="radio"
                            className="form-input"
                            name="status"
                            value="INVOICED"
                            checked={invoiceStateFilter === "INVOICED"}
                            onChange={ev => setInvoiceStateFilter(ev.target.value)}/>
                        <span/>
                    </label>
                    <label className="radio-label">
                        Pendientes
                        <input
                            type="radio"
                            className="form-input"
                            name="status"
                            value="PENDING"
                            checked={invoiceStateFilter === "PENDING"}
                            onChange={ev => setInvoiceStateFilter(ev.target.value)}/>
                        <span/>
                    </label>
                </div>
                <span/>
            </div>
            <div id="monthly-invoices" className="invoice-table">
                <div className="table-row">
                    <span className="table-header">Emisor</span>
                    <span className="table-header">Cliente</span>
                    <span className="table-header">Abonos</span>
                    <span className="table-header">Hostings</span>
                    <span className="table-header">Adicionales</span>
                    <span className="table-header">Servicios</span>
                    <span className="table-header">Total</span>
                    <span className="table-header">Status</span>
                </div>
                {rows}
                {invoices &&
                <div className="table-row total-row">
                    <span className="table-cell item-type">Total</span>
                    <span className="table-cell"/>
                    <span className="table-cell"/>
                    <span className="table-cell"/>
                    <span className="table-cell"/>
                    <span className="table-cell"/>
                    <span className="table-cell detail-amount total-amount amounts">
                        <AmountDisplay amount={invoices.data.total}/>
                    </span>
                    <span className="table-cell"/>
                </div>
                }
            </div>
        </div>
    );
}

export default MonthlyInvoices;
