
export const periodMapping = {
    MONTHLY: 'mensual',
    BIANNUAL: 'semestral',
    YEARLY: 'anual'
};

export const months = [
    { value: 1, label: 'Enero' },
    { value: 2, label: 'Febrero' },
    { value: 3, label: 'Marzo' },
    { value: 4, label: 'Abril' },
    { value: 5, label: 'Mayo' },
    { value: 6, label: 'Junio' },
    { value: 7, label: 'Julio' },
    { value: 8, label: 'Agosto' },
    { value: 9, label: 'Septiembre' },
    { value: 10, label: 'Octubre' },
    { value: 11, label: 'Noviembre' },
    { value: 12, label: 'Diciembre' }
];

export const isMobile = navigator.maxTouchPoints > 0;

export const RESOURCE_CLIENTS = 'clients';
export const RESOURCE_SUBSCRIPTIONS = 'subscriptions';
export const RESOURCE_HOSTINGS = 'hostings';
export const RESOURCE_HOSTING_PLANS = 'hosting-plans';
export const RESOURCE_ADDITIONAL_ITEMS = 'additional-items';
export const RESOURCE_WORKS = 'works';
export const RESOURCE_INVOICES = 'invoices';
export const RESOURCE_SETTINGS = 'settings';
export const RESOURCE_SECURITY = 'security';

export const ROOT_USER = 'root';

export const ITEM_TYPE_SUBSCRIPTION = 'abono';
export const ITEM_TYPE_HOSTING = 'hosting';
export const ITEM_TYPE_ADDITIONAL_ITEM = 'adicional';
export const ITEM_TYPE_SERVICE = 'servicio';

export const invoice_item_types = {
    'subscription': ITEM_TYPE_SUBSCRIPTION,
    'hosting': ITEM_TYPE_HOSTING,
    'additional_item': ITEM_TYPE_ADDITIONAL_ITEM,
    'work': ITEM_TYPE_SERVICE
};
