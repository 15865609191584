import "./AdditionalItems.css";
import axios from "axios";
import { useModal } from "../../components/Modal";
import Command from "../../components/Command";
import AdditionalItem from "../additional-items/AdditionalItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import handleError from "../../misc/ErrorHandling";
import { useContext, useState } from "react";
import { ClientSelector } from "../../components/form/ClientSelect";
import { currentPeriod, formatDate } from "../../misc/Values";
import { SessionContext } from '../../components/Session';
import { RESOURCE_ADDITIONAL_ITEMS } from '../../misc/ReferenceData';
import { useNoCacheAxios } from '../../misc/Net';
import AmountDisplay from '../../components/form/AmountDisplay';
import { PeriodSelect } from '../../components/form/PeriodSelect';

function AdditionalItems() {

    let session = useContext(SessionContext);

    const { canWrite } = session;

    const readOnly = !canWrite(RESOURCE_ADDITIONAL_ITEMS);

    const [clientId, setClientId] = useState("");

    const [period, setPeriod] = useState(currentPeriod);

    const modal = useModal();

    const [{ data: additionalItems, loading }, getAdditionalItems] =
        useNoCacheAxios(`/api/additional-items?client_id=${clientId}&month=${period.month}&year=${period.year}&aggregate=total`);

    const onClientSelect = (selectedClient) =>
        setClientId(selectedClient.id);

    const onAdditionalItemSaved = () =>
        getAdditionalItems()
            .catch(error => handleError("AdditionalItem", error, modal));

    const onGetAdditionalItems = () =>
        getAdditionalItems()
            .catch(error => handleError("AdditionalItem", error, modal));

    const onAdditionalItemSelect = (additionalItemId) => () =>
        modal({
            title: readOnly ? "Detalle de ítem adicional" : "Modificación de ítem adicional",
            style: "dialog",
            component: onClose =>
                <AdditionalItem
                    additionalItemId={additionalItemId}
                    closeDialog={onClose}
                    modal={modal}
                    onAdditionalItemSaved={onAdditionalItemSaved}
                    session={session}/>
        });

    const onAdditionalItemDelete = additionalItemId => () =>
        modal({ title: "Servicio", message: "Confirme que desea eliminar el adicional", confirm: true })
            .then(() => axios.delete(`/api/additional-items/${additionalItemId}`))
            .then(() => getAdditionalItems())
            .catch(error => handleError("Eliminación de ítem adicional", error, modal));

    let rows;
    if (loading || !additionalItems)
        rows = (
            <div className="table-row">
                <span className="table-cell-whole-row">Cargando...</span>
            </div>
        );
    else if (additionalItems.data.additional_items.length === 0)
        rows = (
            <div className="table-row">
                <span className="table-cell-whole-row">No hay ítems adicionales</span>
            </div>
        );
    else
        rows = additionalItems.data.additional_items.map(additionalItem => {
            const onClick = onAdditionalItemSelect(additionalItem.id);
            return (
                <div key={additionalItem.id} className="table-row">
                    <span className="table-cell clickable" onClick={onClick}>{formatDate(additionalItem.date)}</span>
                    <span className="table-cell clickable" onClick={onClick}>{additionalItem.client_name}</span>
                    <span className="table-cell clickable" onClick={onClick}>{additionalItem.description}</span>
                    <span className="table-cell clickable amount" onClick={onClick}>
                        <AmountDisplay
                            amount={additionalItem.unit_price}
                            defaultValue={0}
                            className="form-input total"
                            prefix="$ "/>
                    </span>
                    <span className="table-cell clickable" onClick={onClick}>{additionalItem.quantity}</span>
                    <span className="table-cell clickable" onClick={onClick}>
                        <abbr title={additionalItem.tax_name}>{additionalItem.tax_code}</abbr>
                    </span>
                    <span className="table-cell clickable amount" onClick={onClick}>
                        <AmountDisplay
                            amount={additionalItem.total_price}
                            defaultValue={0}
                            className="form-input total"
                            prefix="$ "/>
                    </span>
                    <span className="table-cell clickable" onClick={onClick}>
                        {additionalItem.monthly_invoice ? "No" : "Si"}
                    </span>
                    <span className="table-cell">
                        {/* TODO deshabilitar si ya está facturado */}
                        <Command onClick={onAdditionalItemDelete(additionalItem.id)} disabled={readOnly}>
                            <FontAwesomeIcon icon="trash-alt"/>
                        </Command>
                    </span>
                </div>
            );
        });

    return (
        <div id="additional-items-container">
            <div id="additional-items-title-container" className="form-grid">
                <h2 id="additional-items-title">
                    Planilla de Adicionales
                    <Command onClick={onGetAdditionalItems}><FontAwesomeIcon icon="sync-alt"/></Command>
                </h2>
            </div>
            <div id="filters-container" className="form-grid">
                <label className="form-label" htmlFor="client-selector">Cliente</label>
                <ClientSelector id="client-selector" defaultLabel="Todos los clientes" onSelect={onClientSelect}/>
                <span/>
                <label className="form-label" htmlFor="month">Período</label>
                <PeriodSelect
                    id="period"
                    period={period}
                    defaultPeriod={currentPeriod}
                    defaultPeriodLabel="Período actual"
                    onSelect={setPeriod}/>
                <span/>
                <label className="form-label">Importe a Facturar</label>
                <AmountDisplay
                    amount={loading ? 0 : additionalItems.data.total}
                    className="form-input total"
                    prefix="$ "/>
                <span/>
            </div>
            <div id="additional-items">
                <div className="table-row">
                    <span className="table-header">Fecha</span>
                    <span className="table-header">Cliente</span>
                    <span className="table-header">Concepto</span>
                    <span className="table-header">Importe</span>
                    <span className="table-header">Cant</span>
                    <span className="table-header">Imp</span>
                    <span className="table-header">Total</span>
                    <span className="table-header"><abbr title="Facturación inmediata">F. Inm.</abbr></span>
                    <span className="table-header">Eliminar</span>
                </div>
                {rows}
            </div>
        </div>
    );
}

export default AdditionalItems;
