import "./Menu.css";
import { useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SessionContext } from "./Session";
import {
    isMobile, RESOURCE_ADDITIONAL_ITEMS,
    RESOURCE_CLIENTS, RESOURCE_HOSTING_PLANS, RESOURCE_HOSTINGS, RESOURCE_INVOICES,
    RESOURCE_SECURITY,
    RESOURCE_SETTINGS,
    RESOURCE_SUBSCRIPTIONS, RESOURCE_WORKS
} from "../misc/ReferenceData";

function Menu(props) {

    const { onClick } = props;

    const [visible, setVisible] = useState(false);

    const { username, canRead, canWrite, logout } = useContext(SessionContext);

    const onMenuIconClick = () => setVisible(!visible);

    const onMenuItemClick = module => () => {
        setVisible(false);
        onClick(module);
    };

    return (
        <nav role="navigation">
            <div id="menuToggle">
                <input type="checkbox" checked={visible} onChange={onMenuIconClick}/>
                <span className="hamburger hamburger-1"/>
                <span className="hamburger hamburger-2"/>
                <span className="hamburger hamburger-3"/>
                <ul id="menu">
                    <li>
                        <section className="session-container-2">
                            <span className="user">{username}</span>
                            <button className="user-logout" onClick={() => logout()}>
                                <abbr title="Salir">
                                    <FontAwesomeIcon icon="sign-out-alt"/>
                                </abbr>
                            </button>
                            <button className="user-settings" onClick={onMenuItemClick("password")}>
                                <abbr title="Panel del usuario">
                                    <FontAwesomeIcon icon="user-cog"/>
                                </abbr>
                            </button>
                        </section>
                    </li>
                    {canRead(RESOURCE_CLIENTS) &&
                    <li className="clickable" onClick={onMenuItemClick("clients")}>
                        Clientes
                        {isMobile && <sup className="menu-warn">&nbsp;&nbsp;⚠️</sup>}
                    </li>
                    }
                    {canRead(RESOURCE_SUBSCRIPTIONS) &&
                    <li className="clickable" onClick={onMenuItemClick("subscriptions")}>
                        Abonos
                        {isMobile && <sup className="menu-warn">&nbsp;&nbsp;⚠️</sup>}
                    </li>
                    }
                    {canRead(RESOURCE_HOSTINGS) &&
                    <li className="clickable" onClick={onMenuItemClick("hostings")}>
                        Hosting
                        {isMobile && <sup className="menu-warn">&nbsp;&nbsp;⚠️</sup>}
                    </li>
                    }
                    {canRead(RESOURCE_HOSTING_PLANS) &&
                    <li className="clickable" onClick={onMenuItemClick("hosting-plans")}>
                        Planes hosting
                        {isMobile && <sup className="menu-warn">&nbsp;&nbsp;⚠️</sup>}
                    </li>
                    }
                    {canWrite(RESOURCE_WORKS) &&
                    <li className="clickable" onClick={onMenuItemClick("work")}>
                        Carga de servicio
                    </li>
                    }
                    {canRead(RESOURCE_WORKS) &&
                    <li className="clickable" onClick={onMenuItemClick("works")}>
                        Planilla de servicios
                        {isMobile && <sup className="menu-warn">&nbsp;&nbsp;⚠️</sup>}
                    </li>
                    }
                    {canWrite(RESOURCE_ADDITIONAL_ITEMS) &&
                    <li className="clickable" onClick={onMenuItemClick("additional-item")}>
                        Carga adicionales
                    </li>
                    }
                    {canRead(RESOURCE_ADDITIONAL_ITEMS) &&
                    <li className="clickable" onClick={onMenuItemClick("additional-items")}>
                        Planilla de adicionales
                        {isMobile && <sup className="menu-warn">&nbsp;&nbsp;⚠️</sup>}
                    </li>
                    }
                    {canRead(RESOURCE_INVOICES) &&
                    <li className="clickable" onClick={onMenuItemClick("monthly-invoices")}>
                        Facturación mensual
                        {isMobile && <sup className="menu-warn">&nbsp;&nbsp;⚠️</sup>}
                    </li>
                    }
                    {canRead(RESOURCE_INVOICES) &&
                    <li className="clickable" onClick={onMenuItemClick("immediate-invoices")}>
                        Facturación inmediata
                        {isMobile && <sup className="menu-warn">&nbsp;&nbsp;⚠️</sup>}
                    </li>
                    }
                    <li className="menu-stretch"/>
                    {isMobile &&
                    <li>
                        <sup className="menu-warn">
                            ⚠️ No preparado para mobile
                        </sup>
                    </li>
                    }
                    {(canRead(RESOURCE_SETTINGS) || canRead(RESOURCE_SECURITY)) &&
                    <li onClick={onMenuItemClick("settings")}>
                        <button>
                            <abbr title="Settings generales">
                                <FontAwesomeIcon icon="cogs"/>
                            </abbr>
                        </button>
                    </li>
                    }
                </ul>
            </div>
        </nav>
    );
}

export default Menu;
