import "./DateInput.css";
import "react-datepicker/dist/react-datepicker.css";
import { Controller } from "react-hook-form";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import { isMobile } from "../../misc/ReferenceData";
import { formatISO, parseISO } from "date-fns";
import { formatDate } from "../../misc/Values";

function DateInput({ id, name, control, className, rules, autoFocus, readOnly }) {

    const component = ({ onChange, value, name }) => {
        if (isMobile)
            return <input
                type="date"
                className="form-input"
                name={name}
                value={value}
                onChange={onChange}
                readOnly={readOnly}
                id={id}/>;
        else if (readOnly)
            return <input
                type="text"
                className="form-input"
                name={name}
                value={formatDate(value)}
                readOnly={true}
                id={id}/>;
        else
            return <DatePicker
                id={id}
                calendarClassName={className}
                className="form-input"
                name={name}
                selected={typeof (value) === "string" ? parseISO(value) : value}
                onChange={date => onChange(formatISO(date, { representation: "date" }))}
                autoFocus={autoFocus}
                locale="es"
                dateFormat="dd/MM/yyyy"
                todayButton={<button>Hoy</button>}
                withPortal/>;
    };

    return (
        <Controller
            render={component}
            name={name}
            control={control}
            rules={rules}/>
    )
}

DateInput.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    control: PropTypes.object.isRequired,
    className: PropTypes.string,
    rules: PropTypes.object,
    autoFocus: PropTypes.bool,
    readOnly: PropTypes.bool
};

DateInput.defaultProps = {
    className: "date-input",
    rules: { required: true },
    autoFocus: false,
    readOnly: false
};

export default DateInput;
