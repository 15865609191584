import './Error.css';

function Error({ id, className, error, description }) {
    return (
        <div id={id} className={className + ' has-tooltip'}>
            <span>{error}</span>
            <span className='tooltip-text'>{description}</span>
        </div>
    );
}

export default Error;
