import "./ImmediateInvoices.css";
import "./Common.css";
import { useModal } from "../../components/Modal";
import Command from "../../components/Command";
import { Invoice } from "./Invoice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import handleError from "../../misc/ErrorHandling";
import { createRef, useContext, useState } from "react";
import { ClientSelector } from "../../components/form/ClientSelect";
import { SessionContext } from "../../components/Session";
import { useNoCacheAxios } from "../../misc/Net";
import { PeriodSelect } from "../../components/form/PeriodSelect";
import { currentPeriod, initials, isOldPeriod } from "../../misc/Values";
import { invoice_item_types, RESOURCE_INVOICES } from '../../misc/ReferenceData';
import { useForm, useWatch } from 'react-hook-form';
import { InvoiceGenerator } from './InvoiceGenerator';
import AmountDisplay from '../../components/form/AmountDisplay';

function ImmediateInvoices() {

    let session = useContext(SessionContext);

    const { canWrite } = session;

    const readOnly = !canWrite(RESOURCE_INVOICES);

    const [clientId, setClientId] = useState("");

    const [period, setPeriod] = useState(currentPeriod);

    const [invoiceStateFilter, setInvoiceStateFilter] = useState("ALL");

    const [descriptionExpands, setDescriptionExpands] = useState({});

    const modal = useModal();

    const [{ data: invoices, loading }, getInvoices] =
        useNoCacheAxios(`/api/charges/immediate?month=${period.month}&year=${period.year}&client_id=${clientId}`);

    const { register, control } = useForm();

    const form = useWatch({ control });

    const onClientSelect = (selectedClient) =>
        setClientId(selectedClient.id);

    const onInvoiceUpdate = () =>
        getInvoices()
            .catch(error => handleError("Facturación", error, modal));

    const onGetInvoices = () =>
        getInvoices()
            .then(() => setDescriptionExpands({}))
            .catch(error => handleError("Facturación", error, modal));

    const onInvoiceSelect = (legalEntityId, clientId, invoiceId) => () =>
        modal({
            title: "Detalle factura emitida",
            style: "dialog",
            component: onClose =>
                <Invoice legalEntityId={legalEntityId}
                         clientId={clientId}
                         invoiceId={invoiceId}
                         closeDialog={onClose}
                         modal={modal}
                         onInvoiceDelete={onInvoiceUpdate}
                         session={session}/>
        });

    const onCopy = descriptionRef => () => {
        if (descriptionRef.current) {
            let range = document.createRange();
            range.selectNode(descriptionRef.current)
            let selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
            document.execCommand("copy");
        }
    };

    const toggleExpand = key => () => {
        descriptionExpands[key] = !(descriptionExpands[key] ?? false);
        setDescriptionExpands({...descriptionExpands});
    };

    let rows;
    if (loading || !invoices)
        rows = (
            <div className="table-row">
                <span className="table-cell-whole-row">Cargando...</span>
            </div>
        );
    else if (invoices.data.charges.length === 0)
        rows = (
            <div className="table-row">
                <span className="table-cell-whole-row">
                    {isOldPeriod(period)
                        ? "No hubo cargos a facturar"
                        : "No hay cargos a facturar"
                    }
                </span>
            </div>
        );
    else
        rows = Object.keys(invoices.data.charges)
            .sort()
            .filter(key =>
                invoiceStateFilter === "ALL" ||
                (invoiceStateFilter === "INVOICED" && invoices.data.charges[key].invoice_id) ||
                (invoiceStateFilter === "PENDING" && !invoices.data.charges[key].invoice_id)
            )
            .map(key => {
                let charge = invoices.data.charges[key];
                const onClick = charge.invoice_id
                    ? onInvoiceSelect(charge.legal_entity_id, charge.client_id, charge.invoice_id)
                    : () => {};
                const clickable = charge.invoice_id ? "clickable" : "";
                let descriptionRef = createRef();
                return (
                    <div key={key} className="table-row">
                        <span className="table-cell invoice-issuer">
                            {charge.legal_entity_name &&
                            <>
                                <span>{initials(charge.legal_entity_name)}</span>
                                &nbsp;-&nbsp;
                                <span className="cuit">{charge.legal_entity_cuit}</span>
                            </>
                            }
                        </span>
                        <span className={`table-cell ${clickable} client`} onClick={onClick}>{charge.client_name}</span>

                        <span className={`table-cell ${clickable} item-type`} onClick={onClick}>
                            {invoice_item_types[charge.type]}
                        </span>

                        <span className={`table-cell ${clickable}`}>
                            <div className="item-description">
                                <span ref={descriptionRef}
                                      className={descriptionExpands[key] ? "expand-description" : ""}
                                      onClick={onClick}>
                                    {charge.description}
                                </span>
                                <Command onClick={toggleExpand(key)}>
                                    <FontAwesomeIcon icon="caret-square-down" title="Expandir"/>
                                </Command>
                                <Command onClick={onCopy(descriptionRef)}>
                                    <FontAwesomeIcon icon="copy" title="Copiar datos"/>
                                </Command>
                            </div>
                        </span>

                        <span className={`table-cell ${clickable} amounts`} onClick={onClick}>
                            <AmountDisplay amount={charge.amount}/>
                        </span>

                        <span className="table-cell">
                            {charge.invoice_id
                                ? <Command onClick={onClick}><FontAwesomeIcon icon="file-invoice-dollar"
                                                                              title="Facturado"/></Command>
                                : (<span className="item-select">
                                    <input
                                        name={`${charge.legal_entity_id}-${charge.client_id}`}
                                        value={`${charge.type}-${charge.item_id}`}
                                        id={`checkbox-${charge.legal_entity_id}-${charge.client_id}-${charge.type}-${charge.item_id}`}
                                        type="checkbox"
                                        className="form-input"
                                        ref={register()}/>
                                    <label className="form-label"
                                           htmlFor={`checkbox-${charge.legal_entity_id}-${charge.client_id}-${charge.type}-${charge.item_id}`}/>
                                </span>)
                            }
                        </span>
                    </div>
                );
            });

    if (rows && rows.length === 0) {
        rows = (
            <div className="table-row">
                <span className="table-cell-whole-row">
                    Ningún registro cumple los criterios de búsqueda
                </span>
            </div>
        );
    }

    return (
        <>
            <div id="invoices-container">
                <div id="invoices-title-container" className="form-grid">
                    <h2 id="invoices-title">
                        Planilla de Facturación Inmediata
                        <Command onClick={onGetInvoices}><FontAwesomeIcon icon="sync-alt"/></Command>
                    </h2>
                </div>
                <div id="filters-container" className="form-grid">
                    <label className="form-label" htmlFor="client-selector">Cliente</label>
                    <ClientSelector id="client-selector" defaultLabel="Todos los clientes" onSelect={onClientSelect}/>
                    <span/>
                    <label className="form-label" htmlFor="month">Período</label>
                    <PeriodSelect
                        id="period"
                        period={period}
                        defaultPeriod={currentPeriod}
                        defaultPeriodLabel="Último período"
                        onSelect={setPeriod}/>
                    <span/>
                    <label className="form-label" htmlFor="status">Estado</label>
                    <div className="options-status options">
                        <label className="radio-label">
                            Todas
                            <input
                                type="radio"
                                className="form-input"
                                name="status"
                                value="ALL"
                                checked={invoiceStateFilter === "ALL"}
                                onChange={ev => setInvoiceStateFilter(ev.target.value)}/>
                            <span/>
                        </label>
                        <label className="radio-label">
                            Facturadas
                            <input
                                type="radio"
                                className="form-input"
                                name="status"
                                value="INVOICED"
                                checked={invoiceStateFilter === "INVOICED"}
                                onChange={ev => setInvoiceStateFilter(ev.target.value)}/>
                            <span/>
                        </label>
                        <label className="radio-label">
                            Pendientes
                            <input
                                type="radio"
                                className="form-input"
                                name="status"
                                value="PENDING"
                                checked={invoiceStateFilter === "PENDING"}
                                onChange={ev => setInvoiceStateFilter(ev.target.value)}/>
                            <span/>
                        </label>
                    </div>
                    <span/>
                </div>
                <div id="immediate-invoices" className="invoice-table">
                    <div className="table-row">
                        <span className="table-header">Emisor</span>
                        <span className="table-header">Cliente</span>
                        <span className="table-header">Concepto</span>
                        <span className="table-header">Descripción</span>
                        <span className="table-header">Total</span>
                        <span className="table-header">Status</span>
                    </div>
                    {rows}
                    {invoices &&
                    <div className="table-row total-row">
                        <span className="table-cell item-type">Total</span>
                        <span className="table-cell"/>
                        <span className="table-cell"/>
                        <span className="table-cell"/>
                        <span className="table-cell detail-amount total-amount amounts">
                            <AmountDisplay amount={invoices.data.total}/>
                        </span>
                        <span className="table-cell"/>
                    </div>
                    }
                </div>
            </div>
            <div className="operations">
                <InvoiceGenerator
                    readOnly={readOnly}
                    charges={invoices?.data?.charges ?? []}
                    selection={form}
                    afterGeneration={onInvoiceUpdate}
                    modal={modal}
                    session={session}/>
            </div>
        </>
    );
}

export default ImmediateInvoices;
