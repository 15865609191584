import "./Hosting.css";
import { useForm } from "react-hook-form";
import axios from "axios";
import handleError from "../../misc/ErrorHandling";
import { Amount, isId, numberOr } from "../../misc/Values";
import { useEffect, useState } from "react";
import { ClientSelectInput } from "../../components/form/ClientSelect";
import FieldError from "../../components/form/FieldError";
import { HostingPlanSelectInput } from "../../components/form/HostingPlanSelect";
import { RESOURCE_HOSTINGS } from "../../misc/ReferenceData";
import DateInput from '../../components/form/DateInput';
import { formatISO } from 'date-fns';
import AmountDisplay from '../../components/form/AmountDisplay';

export function Hosting({ hostingId, closeDialog, modal, onHostingSaved, session }) {

    const { canWrite } = session;

    const readOnly = !canWrite(RESOURCE_HOSTINGS);

    const { register, handleSubmit, errors, control, setValue } = useForm({
        defaultValues: {
            client: null,
            start_date: formatISO(new Date(), { representation: "date" }),
            site: "",
            hosting_plan: null,
            invoice_period: ""
        }
    });

    const [hostingPlan, setHostingPlan] = useState();
    const [invoicePeriod, setInvoicePeriod] = useState();

    let hostingPrice;
    if (hostingPlan && invoicePeriod) {
        let hostingPlanPrice = new Amount(numberOr(hostingPlan.monthly_price, 0));
        switch (invoicePeriod) {
            case "MONTHLY":
                hostingPrice = hostingPlanPrice;
                break
            case "BIANNUAL":
                hostingPrice = hostingPlanPrice.times(6);
                break
            case "YEARLY":
                hostingPrice = hostingPlanPrice.times(12);
                break
            default:
                hostingPrice = new Amount(0);
        }
    } else {
        hostingPrice = new Amount(0);
    }

    useEffect(() => {
        if (isId(hostingId)) {
            axios.get(`/api/hostings/${hostingId}`)
                .then(response => response.data.data)
                .then(hosting => {
                    setValue("client", { id: hosting.client_id, name: hosting.client_name });
                    setValue("start_date", hosting.start_date);
                    setValue("site", hosting.site);
                    setValue("hosting_plan", { id: hosting.hosting_plan_id, name: hosting.hosting_plan_name });
                    setValue("invoice_period", hosting.invoice_period);
                    setInvoicePeriod(hosting.invoice_period);
                    return axios(`/api/hosting-plans/${hosting.hosting_plan_id}`);
                })
                .then(response => response.data.data)
                .then(hostingPlan => setHostingPlan(hostingPlan))
                .catch(error => handleError("Alta/modificación de abono", error, modal));
        }
    }, [setValue, hostingId, modal]);

    const onSubmit = form => {
        const data = {
            client_id: form.client.id,
            start_date: form.start_date,
            site: form.site,
            hosting_plan_id: form.hosting_plan.id,
            invoice_period: form.invoice_period
        };
        const save = isId(hostingId)
            ? axios.put(`/api/hostings/${hostingId}`, data)
            : axios.post(`/api/hostings`, data);
        save.then(() => {
            onHostingSaved();
            closeDialog()
        })
            .catch(error => handleError("Alta/modificación hosting", error, modal));
    }

    const onCancel = () => {
        closeDialog();
    };

    return (
        <div id="hosting-details-container">
            <form onSubmit={handleSubmit(onSubmit)}>
                <section id="hosting-details" className="form-grid ">

                    <label className="form-label" htmlFor="client-select-input">Client</label>
                    <ClientSelectInput
                        control={control}
                        name="client"
                        id="client-select-input"
                        autoFocus={!readOnly}
                        readOnly={readOnly}/>
                    <FieldError id="error-client" errors={errors.client}/>

                    <label className="form-label" htmlFor="start_date">Fecha alta</label>
                    <DateInput control={control} name="start_date" id="start_date" readOnly={readOnly}/>
                    <FieldError id="error-start_date" errors={errors.start_date}/>

                    <label className="form-label" htmlFor="site">Sitio</label>
                    <input type="text"
                           id="site"
                           name="site"
                           className="form-input"
                           readOnly={readOnly}
                           ref={register({
                               required: true,
                               pattern: /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/
                           })}/>
                    <FieldError id="error-site" errors={errors.site}/>

                    <label className="form-label" htmlFor="hosting_plan">Plan</label>
                    <HostingPlanSelectInput
                        control={control}
                        name="hosting_plan"
                        id="hosting_plan-select"
                        onChange={setHostingPlan}
                        readOnly={readOnly}/>
                    <FieldError id="error-hosting_plan" errors={errors.hosting_plan}/>

                    <label className="form-label" htmlFor="invoice_period">Ciclo de Facturación</label>
                    <div className="options-invoice_period options">
                        <label className="radio-label">
                            Mensual
                            <input
                                type="radio"
                                className="form-input"
                                name="invoice_period"
                                value="MONTHLY"
                                onChange={ev => setInvoicePeriod(ev.target.value)}
                                disabled={readOnly}
                                ref={register({ required: true })}/>
                            <span/>
                        </label>
                        <label className="radio-label">
                            Semestral
                            <input
                                type="radio"
                                className="form-input"
                                name="invoice_period"
                                value="BIANNUAL"
                                onChange={ev => setInvoicePeriod(ev.target.value)}
                                disabled={readOnly}
                                ref={register({ required: true })}/>
                            <span/>
                        </label>
                        <label className="radio-label">
                            Anual
                            <input
                                type="radio"
                                className="form-input"
                                name="invoice_period"
                                value="YEARLY"
                                onChange={ev => setInvoicePeriod(ev.target.value)}
                                disabled={readOnly}
                                ref={register({ required: true })}/>
                            <span/>
                        </label>
                    </div>
                    <FieldError id="error-invoice_period" errors={errors.invoice_period}/>

                    <label className="form-label">Importe</label>
                    <AmountDisplay
                        amount={hostingPrice?.toNumber()}
                        defaultValue="…"
                        className="form-input price"
                        prefix="$ "/>
                    <span/>

                    {!readOnly &&
                    <div className="buttons">
                        <button className="save">Guardar</button>
                        <button className="cancel" onClick={onCancel}>Cancelar</button>
                    </div>
                    }

                    {readOnly &&
                    <div className="buttons">
                        <button className="cancel" onClick={onCancel}>Cerrar</button>
                    </div>
                    }
                </section>
            </form>
        </div>
    );
}
