
export const reactSelectStyle = {
    option: (provided, state) => ({
        ...provided,
        color: state.isFocused ? "white" : "black",
        backgroundColor: state.isFocused ? "gray" : "white"
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: "white",
        backgroundColor: "black",
    }),
    input: (provided, state) => ({
        ...provided,
        color: "white",
        backgroundColor: "black"
    }),
    container: (provided, state) => ({
        ...provided,
        color: "white",
        backgroundColor: "black"
    }),
    control: (provided) => ({
        ...provided,
        color: "white",
        backgroundColor: "black",
        borderRadius: 0,
        border: "2px solid white",
        minHeight: "10px",
        maxHeight: "34px"
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        maxHeight: "28px"
    }),
    menuPortal: (provided) => ({
        ...provided,
        zIndex: 200000
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        marginBottom: "4px"
    })
};
