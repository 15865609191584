import { Controller } from "react-hook-form";
import PropTypes from "prop-types";
import { isMobile } from '../../misc/ReferenceData';
import MultiValueInput from './MultiValueInput';

const EMAIL_REGEX = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

function EmailInput({ id, name, control, className, readOnly, autoFocus, rules, multiple }) {

    if (multiple)
        return (
            <MultiValueInput
                id={id}
                name={name}
                control={control}
                className={className}
                autoFocus={autoFocus}
                readOnly={readOnly}
                validateInput={value => EMAIL_REGEX.exec(value)?.[0]}
                placeholder="Ingresar separando con coma, espacio o tab"
                />
        );
    else
        return (
            <Controller
                render={({ onChange, value, name }) => (
                    <input
                        type={isMobile ? "email" : "text"}
                        id={id}
                        name={name}
                        className={className + " form-input"}
                        value={value}
                        onChange={onChange}
                        readOnly={readOnly}
                        autoFocus={autoFocus}/>
                )}
                name={name}
                control={control}
                rules={rules}/>
        );
}

EmailInput.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    control: PropTypes.object.isRequired,
    className: PropTypes.string,
    readOnly: PropTypes.bool,
    autoFocus: PropTypes.bool,
    rules: PropTypes.object,
    multiple: PropTypes.bool
};

EmailInput.defaultProps = {
    className: "",
    readOnly: false,
    autoFocus: false,
    rules: {
        required: true,
        pattern: EMAIL_REGEX
    },
    multiple: false
};

export default EmailInput;
