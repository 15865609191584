import "./HostingPlans.css";
import axios from "axios";
import { useModal } from "../../components/Modal";
import Command from "../../components/Command";
import { HostingPlan } from "./HostingPlan";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import handleError from "../../misc/ErrorHandling";
import { useContext } from 'react';
import { SessionContext } from '../../components/Session';
import { RESOURCE_HOSTING_PLANS } from '../../misc/ReferenceData';
import { useNoCacheAxios } from '../../misc/Net';
import AmountDisplay from '../../components/form/AmountDisplay';

function HostingPlans() {

    let session = useContext(SessionContext);

    const { canWrite } = session;

    const readOnly = !canWrite(RESOURCE_HOSTING_PLANS);

    const [{ data: hostingPlans, loading }, getHostingPlans] = useNoCacheAxios("/api/hosting-plans");

    const modal = useModal();

    const onHostingPlanSaved = () =>
        getHostingPlans()
            .catch(error => handleError("Planes hosting", error, modal));

    const onGetHostingPlans = () =>
        getHostingPlans()
            .catch(error => handleError("Planes hosting", error, modal));

    const onNewHostingPlanClick = () =>
        modal({
            title: "Alta plan hosting",
            style: "dialog",
            component: onClose =>
                <HostingPlan
                    closeDialog={onClose}
                    modal={modal}
                    onHostingPlanSaved={onHostingPlanSaved}
                    session={session}/>
        });

    const onHostingPlanSelect = (hostingPlanId) => () =>
        modal({
            title: readOnly ? "Detalle plan hosting" : "Modificación plan hosting",
            style: "dialog",
            component: onClose =>
                <HostingPlan
                    hostingPlanId={hostingPlanId}
                    closeDialog={onClose}
                    modal={modal}
                    onHostingPlanSaved={onHostingPlanSaved}
                    session={session}/>
        });

    const onHostingPlanDelete = hostingPlanId => () =>
        modal({ title: "Planes hosting", message: "Confirme que desea eliminar el plan hosting", confirm: true })
            .then(() => axios.delete(`/api/hosting-plans/${hostingPlanId}`))
            .then(() => getHostingPlans())
            .catch(error => handleError("Eliminación de plan hosting", error, modal));

    let rows;
    if (loading || !hostingPlans)
        rows = (
            <div className="table-row">
                <span className="table-cell-whole-row">Cargando...</span>
            </div>
        );
    else if (hostingPlans.data.length === 0)
        rows = (
            <div className="table-row">
                <span className="table-cell-whole-row">No hay planes hosting</span>
            </div>
        );
    else
        rows = hostingPlans.data.map(hostingPlan => {
            return (
                <div key={hostingPlan.id} className="table-row">
                    <span className="table-cell clickable"
                          onClick={onHostingPlanSelect(hostingPlan.id)}>{hostingPlan.name}</span>
                    <span className="table-cell clickable"
                          onClick={onHostingPlanSelect(hostingPlan.id)}>{hostingPlan.description}</span>
                    <span className="table-cell clickable amount" onClick={onHostingPlanSelect(hostingPlan.id)}>
                        <AmountDisplay amount={hostingPlan.monthly_price} prefix="$ "/>
                    </span>
                    <span className="table-cell">
                        <Command onClick={onHostingPlanDelete(hostingPlan.id)} disabled={readOnly}>
                            <FontAwesomeIcon icon="trash-alt"/>
                        </Command>
                    </span>
                </div>
            );
        });

    return (
        <>
            <div id="hosting-plans-container">
                <div id="hosting-plans-title-container" className="form-grid">
                    <h2 id="hosting-plans-title">ABM Planes Hosting</h2>
                </div>
                <h3 id="active-hosting-plans-title">
                    Planes Activos
                    <Command onClick={onGetHostingPlans}><FontAwesomeIcon icon="sync-alt"/></Command>
                </h3>
                <div id="hosting-plans">
                    <div className="table-row">
                        <span className="table-header">Plan</span>
                        <span className="table-header">Descripción</span>
                        <span className="table-header">Importe Mensual</span>
                        <span className="table-header">Eliminar</span>
                    </div>
                    {rows}
                </div>
            </div>

            {!readOnly &&
            <div className="operations">
                <button className="new-hosting-plan" onClick={onNewHostingPlanClick}>Nuevo Plan Hosting</button>
            </div>
            }
        </>
    );
}

export default HostingPlans;
