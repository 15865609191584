import './MultiValueInput.css';
import PropTypes from "prop-types";
import { Controller } from 'react-hook-form';
import { createRef } from 'react';

function MultiValueInput(props) {

    const { id, name, control, className, autoFocus, readOnly, minElements, validateInput, placeholder } = props;

    const inputRef = createRef();

    const separators = ["Comma", "Space", "Tab"];

    const onKeyDown = (values, setValues) => ev => {
        const value = validateInput(ev.target.value);
        if (separators.includes(ev.code) && value) {
            if (!values.includes(value)) {
                setValues([...values, value]);
                inputRef.current.value = "";
                if (ev.code !== "Tab")
                    ev.preventDefault();
            }
        } else if (ev.code === "Backspace" && !ev.target.value && values.length > 0) {
            let newValues = [...values];
            newValues.pop();
            setValues(newValues);
        }
    };

    const removeItem = (values, setValues, i) => ev => {
        let newValues = [...values];
        newValues.splice(i, 1)
        setValues(newValues);
        inputRef.current.focus();
        ev.preventDefault();
    };

    return (
        <Controller
            render={({ onChange: setValues, value: values }) => (
                <div className={className + " multi-value"} id={id}>
                    <ul className="multi-value__values">
                        {values.map((value, i) => (
                            <li key={value}>
                                {value}
                                <button onClick={removeItem(values, setValues, i)}>+</button>
                            </li>
                        ))}
                        {!readOnly &&
                        <li className="multi-value__values__input">
                            <input
                                ref={inputRef}
                                autoFocus={autoFocus}
                                placeholder={placeholder}
                                onKeyDown={onKeyDown(values, setValues)}/>
                        </li>
                        }
                    </ul>
                </div>
            )}
            name={name}
            control={control}
            rules={{ validate: values => values.length >= minElements }}/>
    )
}

MultiValueInput.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    control: PropTypes.object.isRequired,
    className: PropTypes.string,
    autoFocus: PropTypes.bool,
    readOnly: PropTypes.bool,
    minElements: PropTypes.number,
    validateInput: PropTypes.func,
    placeholder: PropTypes.string
};

MultiValueInput.defaultProps = {
    className: "",
    autoFocus: false,
    readOnly: false,
    minElements: 1,
    validateInput: input => input
};

export default MultiValueInput;
