import "./Client.css";
import { Controller, useForm } from "react-hook-form";
import axios from "axios";
import handleError from "../../misc/ErrorHandling";
import { isId } from "../../misc/Values";
import { useEffect } from "react";
import FieldError from "../../components/form/FieldError";
import { RESOURCE_CLIENTS } from "../../misc/ReferenceData";
import NumberFormat from "react-number-format";
import { LegalEntitySelectInput } from "../../components/form/LegalEntitySelect";
import { IvaSelectInput } from "../../components/form/IvaSelect";
import EmailInput from '../../components/form/EmailInput';

export function Client({ clientId, closeDialog, modal, onClientSaved, session }) {

    const { canWrite } = session;

    const readOnly = !canWrite(RESOURCE_CLIENTS);

    const { register, handleSubmit, errors, control, setValue } = useForm({
        defaultValues: {
            name: null,
            iva: null,
            cuit: null,
            contact: null,
            emails: [],
            invoice_issuer: null,
            address: null
        }
    });

    const operation = isId(clientId) ? "Modificación" : "Alta";

    useEffect(() => {
        if (isId(clientId)) {
            axios.get(`/api/clients/${clientId}`)
                .then(response => response.data.data)
                .then(client => {
                    setValue("name", client.name);
                    setValue("cuit", client.cuit);
                    setValue("contact", client.contact);
                    setValue("emails", client.emails ?? []);
                    setValue("address", client.address);
                    return Promise.all([
                        axios(`/api/iva/${client.iva_id}`),
                        axios(`/api/legal-entities/${client.invoice_issuer}`)
                    ]);
                })
                .then(([r1, r2]) => [r1.data.data, r2.data.data])
                .then(([iva, legalEntity]) => {
                    setValue("iva", iva);
                    setValue("invoice_issuer", legalEntity);
                })
                .catch(error => handleError(`${operation} de cliente`, error, modal));
        }
    }, [setValue, clientId, modal, operation]);

    const onSubmit = form => {
        const data = {
            name: form.name,
            iva_id: form.iva.id,
            cuit: form.cuit,
            contact: form.contact,
            emails: form.emails,
            invoice_issuer: form.invoice_issuer.id,
            address: form.address
        };
        const save = isId(clientId)
            ? axios.put(`/api/clients/${clientId}`, data)
            : axios.post(`/api/clients`, data);
        save.then(() => {
            onClientSaved();
            closeDialog()
        })
            .catch(error => handleError(`${operation} de cliente`, error, modal));
    }

    const onCancel = () => {
        closeDialog();
    };

    return (
        <div id="client-details-container">
            <form onSubmit={handleSubmit(onSubmit)}>
                <section id="client-details" className="form-grid ">

                    <label className="form-label" htmlFor="name">Nombre</label>
                    <input type="text"
                           id="name"
                           name="name"
                           className="form-input"
                           readOnly={readOnly}
                           autoFocus={!readOnly}
                           ref={register({ required: true, minLength: 2 })}/>
                    <FieldError id="error-name" errors={errors.name}/>

                    <label className="form-label" htmlFor="iva">Condición IVA</label>
                    <IvaSelectInput control={control} name="iva" id="iva"/>
                    <FieldError id="error-iva" errors={errors.iva}/>

                    <label className="form-label" htmlFor="client-cuit">CUIT</label>
                    <Controller
                        render={({ onChange, value, name }) => (
                            <NumberFormat
                                allowNegative={false}
                                onValueChange={(v) => onChange(v.value)}
                                value={value === 0 ? "00000000000" : value}
                                name={name}
                                id="client-cuit"
                                format="##-########-#"
                                className="form-input"
                            />
                        )}
                        name="cuit"
                        control={control}
                        rules={{ required: true, pattern: /^[0-9]{2}-?[0-9]{8}-?[0-9]$/, valueAsNumber: true }}/>
                    <FieldError id="error-cuit" errors={errors.cuit}/>

                    <label className="form-label" htmlFor="client-contact">Contacto</label>
                    <input
                        type="text"
                        className="form-input"
                        name="contact"
                        id="client-contact"
                        ref={register({ required: true, maxLength: 200 })}/>
                    <FieldError id="error-contact" errors={errors.contact}/>

                    <label className="form-label" htmlFor="emails">E-mail</label>
                    <EmailInput control={control} name="emails" id="emails" multiple={true}/>
                    <FieldError id="error-emails" errors={errors.emails}/>

                    <label className="form-label" htmlFor="invoice_issuer">Fact preferida</label>
                    <LegalEntitySelectInput control={control} name="invoice_issuer" id="invoice_issuer"/>
                    <FieldError id="error-invoice_issuer" errors={errors.invoice_issuer}/>

                    <label className="form-label" htmlFor="client-address">Dirección</label>
                    <input
                        type="text"
                        className="form-input"
                        name="address"
                        id="client-address"
                        ref={register({ required: true, maxLength: 200 })}/>
                    <FieldError id="error-address" errors={errors.address}/>

                    {!readOnly &&
                    <div className="buttons">
                        <button className="save">Guardar</button>
                        <button className="cancel" onClick={onCancel}>Cancelar</button>
                    </div>
                    }

                    {readOnly &&
                    <div className="buttons">
                        <button className="cancel" onClick={onCancel}>Cerrar</button>
                    </div>
                    }
                </section>
            </form>
        </div>
    );
}
