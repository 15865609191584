import "./Hostings.css";
import axios from "axios";
import { useModal } from "../../components/Modal";
import Command from "../../components/Command";
import { Hosting } from "./Hosting";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import handleError from "../../misc/ErrorHandling";
import { useContext, useState } from "react";
import { ClientSelector } from "../../components/form/ClientSelect";
import { MonthSelector } from "../../components/form/MonthSelect";
import { periodMapping, RESOURCE_HOSTINGS } from "../../misc/ReferenceData";
import { SessionContext } from "../../components/Session";
import { useNoCacheAxios } from '../../misc/Net';
import { formatDate } from '../../misc/Values';
import AmountDisplay from '../../components/form/AmountDisplay';

function Hostings() {

    const session = useContext(SessionContext);

    const { canWrite } = session;

    const readOnly = !canWrite(RESOURCE_HOSTINGS);

    const [clientId, setClientId] = useState("");

    const [month, setMonth] = useState("");

    const modal = useModal();

    const [{ data: hostings, loading }, getHostings] =
        useNoCacheAxios(`/api/hostings?client_id=${clientId}&start_month=${month}`);

    const onClientSelect = (selectedClient) =>
        setClientId(selectedClient.id);

    const onMonthSelect = (selectedMonth) =>
        setMonth(selectedMonth.value);

    const onHostingSaved = () =>
        getHostings()
            .catch(error => handleError("Hosting", error, modal));

    const onGetHostings = () =>
        getHostings()
            .catch(error => handleError("Hosting", error, modal));

    const onNewHostingClick = () =>
        modal({
            title: "Alta hosting",
            style: "dialog",
            component: onClose =>
                <Hosting closeDialog={onClose}
                         modal={modal}
                         onHostingSaved={onHostingSaved}
                         session={session}/>
        });

    const onHostingSelect = (hostingId) => () =>
        modal({
            title: readOnly ? "Detalle hosting" : "Modificación hosting",
            style: "dialog",
            component: onClose =>
                <Hosting hostingId={hostingId}
                         closeDialog={onClose}
                         modal={modal}
                         onHostingSaved={onHostingSaved}
                         session={session}/>
        });

    const onHostingDelete = hostingId => () =>
        modal({ title: "Hosting", message: "Confirme que desea eliminar el hosting", confirm: true })
            .then(() => axios.delete(`/api/hostings/${hostingId}`))
            .then(() => getHostings())
            .catch(error => handleError("Eliminación de hosting", error, modal));

    let rows;
    if (loading || !hostings)
        rows = (
            <div className="table-row">
                <span className="table-cell-whole-row">Cargando...</span>
            </div>
        );
    else if (hostings.data.length === 0)
        rows = (
            <div className="table-row">
                <span className="table-cell-whole-row">No hay hostings</span>
            </div>
        );
    else
        rows = hostings.data.map(hosting => {
            return (
                <div key={hosting.id} className="table-row">
                    <span className="table-cell clickable"
                          onClick={onHostingSelect(hosting.id)}>{hosting.client_name}</span>
                    <span className="table-cell clickable" onClick={onHostingSelect(hosting.id)}>{hosting.site}</span>
                    <span className="table-cell clickable"
                          onClick={onHostingSelect(hosting.id)}>{hosting.hosting_plan_name}</span>
                    <span className="table-cell clickable"
                          onClick={onHostingSelect(hosting.id)}>{periodMapping[hosting.invoice_period]}</span>
                    <span className="table-cell clickable"
                          onClick={onHostingSelect(hosting.id)}>{formatDate(hosting.start_date)}</span>
                    <span className="table-cell clickable amount" onClick={onHostingSelect(hosting.id)}>
                        <AmountDisplay amount={hosting.price} prefix="$ "/>
                    </span>
                    <span className="table-cell">
                        <Command onClick={onHostingDelete(hosting.id)} disabled={readOnly}>
                            <FontAwesomeIcon icon="trash-alt"/>
                        </Command>
                    </span>
                </div>
            );
        });

    return (
        <>
            <div id="hostings-container">
                <div id="hostings-title-container" className="form-grid">
                    <h2 id="hostings-title">ABM Hosting</h2>
                </div>
                <div id="filters-container" className="form-grid">
                    <label htmlFor="client-selector">Cliente</label>
                    <ClientSelector id="client-selector" defaultLabel="Todos los clientes" onSelect={onClientSelect}/>
                    <label htmlFor="month-selector">Mes</label>
                    <MonthSelector id="month-selector" defaultLabel="Todos los meses" onSelect={onMonthSelect}/>
                </div>
                <h3 id="active-hostings-title">
                    Hosting Activos
                    <Command onClick={onGetHostings}><FontAwesomeIcon icon="sync-alt"/></Command>
                </h3>
                <div id="hostings">
                    <div className="table-row">
                        <span className="table-header">Cliente</span>
                        <span className="table-header">Sitio</span>
                        <span className="table-header">Plan</span>
                        <span className="table-header">Ciclo</span>
                        <span className="table-header">Alta</span>
                        <span className="table-header">Importe</span>
                        <span className="table-header">Eliminar</span>
                    </div>
                    {rows}
                </div>
            </div>

            {!readOnly &&
            <div className="operations">
                <button className="new-hosting-plan" onClick={onNewHostingClick}>Nuevo Hosting</button>
            </div>
            }
        </>
    );
}

export default Hostings;
