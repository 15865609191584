import "./Settings.css";
import axios from "axios";
import { useModal } from "../../components/Modal";
import Command from "../../components/Command";
import { UserDialog } from "./UserDialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import handleError from "../../misc/ErrorHandling";
import { InvoicingForm } from "./InvoicingForm";
import { useContext } from "react";
import { SessionContext } from "../../components/Session";
import { RESOURCE_SECURITY, RESOURCE_SETTINGS, ROOT_USER } from "../../misc/ReferenceData";
import { useNoCacheAxios } from '../../misc/Net';

function Settings() {

    const { canRead } = useContext(SessionContext);

    const canReadSecurity = canRead(RESOURCE_SECURITY);
    const canReadSettings = canRead(RESOURCE_SETTINGS);

    return (
        <div id="settings-container">
            <div id="settings-title-container" className="form-grid">
                <h2 id="settings-title">Settings Generales</h2>
            </div>
            {canReadSecurity && <Users/>}
            {canReadSettings && <InvoicingForm/>}
        </div>
    );
}

function Users() {

    const session = useContext(SessionContext);

    const { canWrite } = session;

    const readOnly = !canWrite(RESOURCE_SECURITY);

    const [{ data: users, loading }, getUsers] = useNoCacheAxios("/api/users");

    const modal = useModal();

    const onUserSaved = () => getUsers();

    const onNewUserClick = () =>
        modal({
            title: "Alta nuevo usuario",
            style: "dialog",
            component: onClose =>
                <UserDialog closeDialog={onClose} modal={modal} onUserSaved={onUserSaved} session={session}/>
        });

    const onUserSelect = (userId) => () =>
        modal({
            title: readOnly ? "Detalle usuario" : "Modificación usuario",
            style: "dialog",
            component: onClose =>
                <UserDialog userId={userId} closeDialog={onClose} modal={modal} onUserSaved={onUserSaved} session={session}/>
        });

    const onUserDelete = userId => () =>
        modal({ title: "Usuarios", message: "Confirme que desea eliminar el usuario", confirm: true })
            .then(() => axios.delete(`/api/users/${userId}`))
            .then(() => getUsers())
            .catch(error => handleError("Eliminación de usuario", error, modal));

    let rows;
    if (loading)
        rows = (
            <div className="table-row">
                <span className="table-cell-whole-row">Cargando...</span>
            </div>
        );
    else if (users.data.length === 0)
        rows = (
            <div className="table-row">
                <span className="table-cell-whole-row">No hay usuarios, entonces... quién sos? 🤨</span>
            </div>
        );
    else
        rows = users.data.map(user => {
            return (
                <div key={user.id} className="table-row">
                    <span className="table-cell clickable" onClick={onUserSelect(user.id)}>{user.username}</span>
                    <span className="table-cell clickable" onClick={onUserSelect(user.id)}>{user.full_name}</span>
                    <span className="table-cell clickable" onClick={onUserSelect(user.id)}>{user.email}</span>
                    <span className="table-cell">
                        <Command onClick={onUserDelete(user.id)} disabled={user.username === ROOT_USER || readOnly}>
                            <FontAwesomeIcon icon="trash-alt"/>
                        </Command>
                    </span>
                </div>
            );
        });

    return (
        <>
            <h3 id="settings-users-title">
                Usuarios
                <Command onClick={() => getUsers()}><FontAwesomeIcon icon="sync-alt"/></Command>
            </h3>
            <div id="settings-users-container">
                <div id="settings-users">
                    <div className="table-row">
                        <span className="table-header">Usuario</span>
                        <span className="table-header">Nombre</span>
                        <span className="table-header">Email</span>
                        <span className="table-header">Eliminar</span>
                    </div>
                    {rows}
                </div>

                {!readOnly &&
                <div className="buttons">
                    <button className="new-user" onClick={onNewUserClick}>Nuevo usuario</button>
                </div>
                }
            </div>
        </>
    );
}

export default Settings;
