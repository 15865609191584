
export const tableStyles = {
    table: {
        backgroundColor: "white",
        borderCollapse: "collapse",
        width: "100%"
    },
    cellInvoiceHeader: {
        border: "thin solid black",
        color: "black",
        textAlign: "left",
        fontWeight: "bold"
    },
    cellInvoiceHeaderData: {
        border: "thin solid black",
        color: "black",
        textAlign: "left"
    },
    emailList: {
        margin: "0",
        padding: "0",
        listStyle: "none"
    },
    cell: {
        border: "thin solid black",
        color: "black"
    },
    typeCell: {
        border: "thin solid black",
        color: "black",
        width: "80px"
    },
    amountCell: {
        border: "thin solid black",
        color: "black",
        textAlign: "right",
        width: "100px"
    },
    hours: {
        border: "thin solid black",
        color: "black",
        textAlign: "right",
        width: "30px"
    },
    amountValue: {
        fontFamily: "monospace"
    },
    taxCell: {
        border: "thin solid black",
        color: "black",
        textAlign: "right",
        width: "100px"
    },
    taxCode: {
        float: "left"
    },
    taxRate: {
        float: "right",
        textAlign: "right",
        fontFamily: "monospace"
    }
}
