import { Amount, initials } from '../../misc/Values';
import axios from 'axios';
import handleError from '../../misc/ErrorHandling';
import AmountDisplay from '../../components/form/AmountDisplay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Command from '../../components/Command';
import { Invoice } from './Invoice';
import PropTypes from 'prop-types';

function InvoiceGenerator({ readOnly, charges, selection, afterGeneration, modal, session }) {

    if (readOnly)
        return (<></>);

    const findNames = (legalEntityId, clientId) =>
        charges.find(charge =>
            charge.legal_entity_id === parseInt(legalEntityId) && charge.client_id === parseInt(clientId));

    const findCharge = (type, itemId) =>
        charges.find(charge =>
            charge.type === type && charge.item_id === parseInt(itemId));

    const invoices = Object.keys(selection)
        .sort()
        .flatMap(key => {
            let [legalEntityId, clientId] = key.split("-");
            let { legal_entity_name, client_name } = findNames(legalEntityId, clientId);
            let clientChargeCodes =
                typeof (selection[key]) === "string"
                    ? [selection[key]]
                    : (selection[key] || []);
            if (clientChargeCodes.length > 0) {
                let clientCharges = clientChargeCodes.map(cc => {
                    let [type, itemId] = cc.split("-");
                    let {
                        amount, description, date, hours, unit_price, tax_code, tax_rate
                    } = findCharge(type, itemId);
                    return {
                        type, itemId, amount, description, date, hours,
                        unit_price: unit_price ?? amount,
                        tax_code: tax_code ?? "N/A",
                        tax_rate: tax_rate ?? 0,
                    };
                });
                return [{
                    legalEntityId,
                    legalEntityName: initials(legal_entity_name),
                    clientId,
                    clientName: client_name,
                    clientCharges,
                    total: clientCharges.reduce((total, charge) => total.plus(new Amount(charge.amount)), new Amount(0))
                }];
            } else
                return [];
        });

    const previewInvoice = (invoice) => () => {
        const invoiceData = {
            data: {
                total: invoice.total.toNumber(),
                charges: invoice.clientCharges
            }
        };
        modal({
            title: "Detalle factura pendiente",
            style: "dialog",
            component: onClose =>
                <Invoice legalEntityId={parseInt(invoice.legalEntityId)}
                         clientId={parseInt(invoice.clientId)}
                         invoiceData={invoiceData}
                         closeDialog={onClose}
                         modal={modal}
                         session={session}/>
        });
    };

    const preview = invoices.map(invoice =>
        <li key={`${invoice.legalEntityName}-${invoice.clientName}`}>
            <div className="invoice-preview">
                <span className="names-bar">
                    <span className="names">
                        {invoice.legalEntityName} - {invoice.clientName}
                    </span>
                    <span className="preview">
                        <Command onClick={previewInvoice(invoice)}>
                            <FontAwesomeIcon icon="eye" title="Previsualizar"/>
                        </Command>
                    </span>
                </span>
                <span className="total">
                    <span/>
                    <AmountDisplay amount={invoice.total.toNumber()}/>
                </span>
            </div>
        </li>
    );

    const generateInvoices = () => {
        const data = {
            invoices: invoices.map(client => ({
                    legal_entity_id: client.legalEntityId,
                    client_id: client.clientId,
                    items: client.clientCharges.map(charge => ({
                        type: charge.type,
                        item_id: charge.itemId
                    }))
                })
            )
        };
        axios.post(`/api/invoices/immediate`, data)
            .then(() => afterGeneration())
            .catch(error => handleError("Generación de factura", error, modal));
    };

    return (
        <div>
            <button disabled={invoices.length === 0} onClick={generateInvoices}>
                Generar factura{invoices.length > 1 ? "s" : ""}
            </button>
            <ul className="invoices-preview">
                {preview}
            </ul>
        </div>
    );
}

InvoiceGenerator.propTypes = {
    readOnly: PropTypes.bool.isRequired,
    charges: PropTypes.array.isRequired,
    selection: PropTypes.object.isRequired,
    afterGeneration: PropTypes.func.isRequired,
    modal: PropTypes.func.isRequired,
    session: PropTypes.object.isRequired
};

InvoiceGenerator.defaultProps = {

};

export { InvoiceGenerator };
