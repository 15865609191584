import './Home.css';

function Home() {

    return (
        <section id='home'>
            <h2>Welcome to the jungle</h2>
            <p>We have nodes and frames</p>
        </section>
    );
}

export default Home;
