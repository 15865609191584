import PropTypes from "prop-types";
import { months } from "../../misc/ReferenceData";
import { reactSelectStyle } from "../../misc/ReactSelectStyle";
import Select from "react-select";
import { Controller } from "react-hook-form";

function MonthSelectInput({ id, name, className, control, defaultLabel, rules, autoFocus, readOnly }) {

    return (
        <Controller
            render={({ onChange, value, name }) => {
                if (readOnly)
                    return <input
                        type="text"
                        id={id}
                        name={name}
                        className={className + " form-input"}
                        value={value?.label}
                        readOnly={true}
                        autoFocus={autoFocus}/>;
                else
                    return <Select
                        className={className + " react-select"}
                        inputId={id}
                        name={name}
                        styles={reactSelectStyle}
                        options={months}
                        value={value}
                        placeholder={defaultLabel ?? "Seleccione mes"}
                        menuPortalTarget={document.body}
                        menuPosition="fixed"
                        onChange={onChange}/>;
            }}
            name={name}
            control={control}
            rules={rules}/>
    );
}

MonthSelectInput.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    control: PropTypes.object.isRequired,
    className: PropTypes.string,
    defaultLabel: PropTypes.string,
    rules: PropTypes.object,
    autoFocus: PropTypes.bool,
    readOnly: PropTypes.bool
};

MonthSelectInput.defaultProps = {
    className: "month-select-input",
    rules: { required: true },
    autoFocus: false,
    readOnly: false
};

function MonthSelector({ id, name, className, defaultLabel, onSelect, initialMonth }) {

    const defaultOption = { value: "", label: defaultLabel ?? "Seleccione mes" };

    return (
        <Select
            className={className + " react-select"}
            inputId={id}
            name={name}
            styles={reactSelectStyle}
            defaultValue={initialMonth ? months[initialMonth - 1] : defaultOption}
            options={[defaultOption, ...months]}
            menuPortalTarget={document.body}
            menuPosition="fixed"
            onChange={onSelect}/>
    );
}

MonthSelector.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    className: PropTypes.string,
    defaultLabel: PropTypes.string,
    onSelect: PropTypes.func,
    initialMonth: PropTypes.number
};

MonthSelector.defaultProps = {
    className: "month-selector",
    onSelect: () => {
    }
};

export { MonthSelectInput, MonthSelector };
