import PropTypes from "prop-types";

function DescriptionInput({ id, name, register, className, rules, autoFocus, readOnly }) {

    return (
        <textarea
            name={name}
            id={id}
            className={className + " form-input"}
            rows={5}
            autoFocus={autoFocus}
            readOnly={readOnly}
            ref={register(rules)}/>
    )
}

DescriptionInput.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    register: PropTypes.func.isRequired,
    className: PropTypes.string,
    rules: PropTypes.object,
    autoFocus: PropTypes.bool,
    readOnly: PropTypes.bool
};

DescriptionInput.defaultProps = {
    className: "",
    rules: { required: true },
    autoFocus: false,
    readOnly: false
};

export default DescriptionInput;
