import React from 'react';
import { createPortal } from 'react-dom';
import { isMobile } from '../misc/ReferenceData';

// TODO el resize de viewport achica proporcionalmente todo el popup
const wrapperStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000
};

const maskStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 100000
};

const modalContainerStyle = (depth) => ({
    position: 'relative',
    zIndex: 100001 + depth,
    border: '3px solid white',
    padding: '25px',
    backgroundColor: 'black',
    width: isMobile ? 'unset' : '500px',
    display: 'grid',
    gridTemplateColumns: '100%',
    gridTemplateRows: '7vh auto 7vh'
});

const dialogContainerStyle = (depth) => ({
    position: 'relative',
    zIndex: 100001 + depth,
    border: '3px solid white',
    padding: '25px',
    backgroundColor: 'black',
    width: '800px',
    display: 'grid',
    gridTemplateColumns: '100%',
    gridTemplateRows: '10% auto 10%',
    maxHeight: '90vh',
    overflow: 'auto'
});

const titleStyle = {
    gridColumn: '1 / span 1',
    gridRow: '1 / span 1',
    placeSelf: 'stretch',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    height: '32px',
    borderBottom: '1px solid gray',
    marginBottom: '10px',
    lineHeight: '24px'
};

const messageStyle = {
    gridColumn: '1 / span 1',
    gridRow: '2 / span 1',
    placeSelf: 'stretch',
    overflow: 'auto',
    maxHeight: '180px'
};

const dialogStyle = {
    gridColumn: '1 / span 1',
    gridRow: '2 / span 1',
    placeSelf: 'stretch'
};

const buttonsStyle = {
    borderTop: '1px solid gray',
    marginTop: '10px',
    paddingTop: '18px',
    gridColumn: '1 / span 1',
    gridRow: '3 / span 1',
    placeSelf: 'stretch',
    display: 'flex',
    flexDirection: 'row-reverse',
    gap: '8px'
};

const cancelButtonStyle = {
    paddingLeft: '1em',
    paddingRight: '1em'
};

const okButtonStyle = {
    paddingLeft: '1em',
    paddingRight: '1em'
};

const ModalContext = React.createContext(Promise.reject);

export const MODAL_OK = 'modal-ok';

export const MODAL_CANCELED = 'modal-canceled';

export const useModal = () => React.useContext(ModalContext);

export const ModalProvider = ({ children }) => {

    const [modalStates, setModalStates] = React.useState([]);

    const responsePromise = React.useRef([]);

    const openModal = (options) => {
        let promise = new Promise((resolve, reject) => {
            responsePromise.current.push({ options, resolve, reject });
            setModalStates(responsePromise.current.map(rp => rp.options));
        });
        // console.group('Open modal');
        // console.log(modalStates);
        // console.log(responsePromise.current);
        // console.groupEnd();
        return promise;
    };

    const onSubmit = (ev) => {
        ev.preventDefault();
        if (responsePromise.current.length > 0) {
            responsePromise.current.pop().resolve(MODAL_OK);
            setModalStates(responsePromise.current.map(rp => rp.options));
        }
        // console.group('Accept modal');
        // console.log(modalStates);
        // console.log(responsePromise.current);
        // console.groupEnd();
    };

    const onClose = () => {
        if (responsePromise.current.length > 0) {
            let promise = responsePromise.current.pop();
            if (promise.options.confirm)
                promise.reject(MODAL_CANCELED);
            else
                promise.resolve(MODAL_OK);
            setModalStates(responsePromise.current.map(rp => rp.options));
        }
        // console.group('Cancel modal');
        // console.log(modalStates);
        // console.log(responsePromise.current);
        // console.groupEnd();
    };

    // console.group('Render modals');
    // console.log(modalStates);
    // console.log(responsePromise.current);
    // console.groupEnd();
    const dialogs = modalStates.map((modalState, index) => {
        if (modalState.style === 'dialog')
            return (
                <div style={wrapperStyle} key={index}>
                    <div style={maskStyle} onClick={onClose} />
                    <div style={dialogContainerStyle(index)}>
                        <span style={titleStyle}>{modalState.title}</span>
                        <div style={dialogStyle}>
                            {modalState.component(onClose)}
                        </div>
                    </div>
                </div>
            );
        else
            return (
                <div style={wrapperStyle} key={index}>
                    <div style={maskStyle} onClick={onClose} />
                    <form onSubmit={() => false}>
                        <div style={modalContainerStyle(index)}>
                            <span style={titleStyle}>{modalState.title}</span>
                            <div style={messageStyle}>
                                {modalState.message}
                            </div>
                            <div style={buttonsStyle}>
                                <button style={okButtonStyle} onClick={onSubmit} autoFocus={true}>OK</button>
                                {modalState.confirm && <button style={cancelButtonStyle} onClick={onClose}>Cancelar</button>}
                            </div>
                        </div>
                    </form>
                </div>
            );
    });

    return createPortal(
        <>
            <ModalContext.Provider value={openModal} children={children} />
            {dialogs}
        </>,
        document.getElementById('root')
    );
};
