import "./Confirm.css";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm } from "react-hook-form";

function Confirm({ message, closeDialog, modal, onConfirm: parentOnConfirm }) {

    const { register, handleSubmit } = useForm({ defaultValues: { confirm: "" } });

    const onSubmit = form => {
        if (form.confirm.toUpperCase() === "CONFIRMAR") {
            parentOnConfirm();
            closeDialog()
        }
        else
            modal({ title: "Cliente", message: "Operación cancelada" })
                .then(() => closeDialog());
    };

    return (
        <div className="confirm">
            <form onSubmit={handleSubmit(onSubmit)}>
                <p>
                    {message}
                    <br/>
                    Ingrese “CONFIRMAR” para continuar con esta operación
                    <br/>
                    <FontAwesomeIcon icon="arrow-down"/>
                    <br/>
                    <input
                        name="confirm"
                        className="confirm-input"
                        maxLength={9}
                        autoFocus={true}
                        ref={register({ required: true, min: 9, max: 9 })}/>
                </p>

                <div className="buttons">
                    <button>Confirmar</button>
                    <button onClick={closeDialog}>Cancelar</button>
                </div>
            </form>
        </div>
    );
}

Confirm.propTypes = {
    message: PropTypes.node.isRequired,
    closeDialog: PropTypes.func,
    modal: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired
};

Confirm.defaultProps = {
    closeDialog: () => {},
};

export default Confirm;
