import "./Invoice.css";
import { RESOURCE_INVOICES } from "../../misc/ReferenceData";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import handleError from "../../misc/ErrorHandling";
import { useRef } from 'react';
import { useNoCacheAxios } from '../../misc/Net';
import { InvoiceHeader } from './InvoiceHeader';
import { InvoiceItems } from './InvoiceItems';

function ClientMonthlyCharges(props) {

    const { legalEntityId, clientId, month, year, closeDialog, modal, onInvoiceSaved, session } = props;

    const { canWrite } = session;

    const readOnly = !canWrite(RESOURCE_INVOICES);

    const [{ data: invoice }, refetch] =
        useNoCacheAxios(`/api/charges/monthly/${legalEntityId}/${clientId}/${year}/${month}`);

    const [{ data: client, loading: loadingClient }] = useNoCacheAxios(`/api/clients/${clientId}`);

    const [{ data: iva }] = useNoCacheAxios(
        { url: `/api/iva/${client?.data.iva_id}` },
        { manual: loadingClient }
    );

    const [{ data: legalEntity }] = useNoCacheAxios(`/api/legal-entities/${legalEntityId}`);

    const generateInvoice = () => {
        const data = {
            month: invoice.data.month,
            year: invoice.data.year,
            client_id: clientId,
            legal_entity_id: legalEntityId
        };
        axios.post(`/api/invoices/monthly`, data)
            .then(() => onInvoiceSaved())
            .then(() => refetch())
            .catch(error => handleError("Generación de factura", error, modal));
    };

    const cancelInvoice = () => {
        let invoiceIds = [...new Set(invoice.data.charges.map(charge => charge.invoice_id))];
        let deletions = invoiceIds.map(invoiceId => axios.delete(`/api/invoices/${invoiceId}`));
        Promise.all(deletions)
            .then(() => onInvoiceSaved())
            .then(() => refetch())
            .catch(error => handleError("Eliminación de factura", error, modal));
    }

    const onCancel = () => {
        closeDialog();
    };

    const onCopy = () => {
        if (invoiceItemsTable.current) {
            let range = document.createRange();
            range.selectNode(invoiceItemsTable.current)
            let selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
            document.execCommand("copy");
        }
    };

    const nothingInvoiced =
        invoice?.data && invoice?.data?.charges?.find(item => item.invoice_item_seq !== null) === undefined;

    // const invoiceId =
    //     invoice?.data && invoice?.data?.charges?.find(item => item.invoice_id !== null)?.invoice_id;

    const invoiceItemsTable = useRef(null);

    return (
        <div id="invoice-details">

            <table style={{ borderCollapse: "collapse" }}>
                <tr>
                    <td>
                        <InvoiceHeader client={client} iva={iva} invoice={invoice} legalEntity={legalEntity}/>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div ref={invoiceItemsTable}>
                            <InvoiceItems invoice={invoice}/>
                        </div>
                    </td>
                </tr>
            </table>

            <div className="form-grid">
                {!readOnly &&
                <div className="buttons">
                    <button className="cancel" onClick={onCancel}>Cerrar</button>

                    <div className="stretch"/>
                    {nothingInvoiced
                        ? <button className="save" onClick={generateInvoice}>Generar factura</button>
                        : <button className="save" onClick={cancelInvoice}>Anular factura</button>
                    }
                    {/*{invoiceId &&*/}
                    {/*<a className="download" href={`/api/invoices/${invoiceId}/spreadsheet`}>*/}
                    {/*    <FontAwesomeIcon icon="download" title="Planilla"/>*/}
                    {/*</a>*/}
                    {/*}*/}
                    <button className="copy" onClick={onCopy}>
                        <FontAwesomeIcon icon="copy" title="Copiar datos"/>
                    </button>
                </div>
                }

                {readOnly &&
                <div className="buttons">
                    <button className="cancel" onClick={onCancel}>Cerrar</button>
                    <div className="stretch"/>
                    {/*{invoiceId &&*/}
                    {/*<a className="download" href={`/api/invoices/${invoiceId}/spreadsheet`}>*/}
                    {/*    <FontAwesomeIcon icon="download" title="Planilla"/>*/}
                    {/*</a>*/}
                    {/*}*/}
                    <button className="copy" onClick={onCopy}>
                        <FontAwesomeIcon icon="copy" title="Copiar datos"/>
                    </button>
                </div>
                }
            </div>
        </div>
    );
}

ClientMonthlyCharges.propTypes = {
    legalEntityId: PropTypes.number.isRequired,
    clientId: PropTypes.number.isRequired,
    month: PropTypes.number.isRequired,
    year: PropTypes.number.isRequired,
    closeDialog: PropTypes.func.isRequired,
    modal: PropTypes.func.isRequired,
    onInvoiceSaved: PropTypes.func,
    session: PropTypes.object.isRequired
};

ClientMonthlyCharges.defaultProps = {
    onInvoiceSaved: () => {
    }
};

export { ClientMonthlyCharges };
