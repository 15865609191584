import { reactSelectStyle } from "../../misc/ReactSelectStyle";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import axios from "axios";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";

function LegalEntitySelectInput(props) {

    const {
        id,
        name,
        control,
        className,
        defaultLabel,
        rules,
        autoFocus,
        onChange: parentOnChange,
        readOnly,
        preferred
    } = props;

    const findLegalEntities = term =>
        axios.get("/api/legal-entities", { params: { term, fields: "id,name" } })
            .then(response => response.data.data)
            .then(les => les.map(le => {
                if (preferred && le.id === preferred)
                    le.name = le.name + " (preferido)";
                return le;
            }));

    return (
        <Controller
            render={({ onChange: controllerOnChange, value }) => {
                if (readOnly)
                    return <input
                        type="text"
                        id={id}
                        name={name}
                        className={className + " form-input"}
                        value={value?.name}
                        readOnly={true}
                        autoFocus={autoFocus}/>;
                else
                    return <AsyncSelect
                        key={preferred}
                        inputId={id}
                        className={className + " react-select"}
                        styles={reactSelectStyle}
                        value={value}
                        defaultOptions={true}
                        loadOptions={findLegalEntities}
                        cacheOptions={false}
                        getOptionLabel={le => le.name}
                        getOptionValue={le => le}
                        placeholder={defaultLabel ?? "Seleccione Emisor"}
                        autoFocus={autoFocus}
                        menuPortalTarget={document.body}
                        menuPosition="fixed"
                        onChange={v => {
                            controllerOnChange(v);
                            parentOnChange(v);
                        }}/>;
            }}
            name={name}
            control={control}
            rules={rules}/>
    );
}

LegalEntitySelectInput.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    control: PropTypes.object.isRequired,
    className: PropTypes.string,
    defaultLabel: PropTypes.string,
    rules: PropTypes.object,
    autoFocus: PropTypes.bool,
    onChange: PropTypes.func,
    readOnly: PropTypes.bool,
    preferred: PropTypes.number
};

LegalEntitySelectInput.defaultProps = {
    className: "legal-entity-select",
    rules: { required: true },
    autoFocus: false,
    onChange: () => {
    },
    readOnly: false
};

export { LegalEntitySelectInput };
