import PropTypes from 'prop-types';
import Error from '../Error';
import { isMobile } from '../../misc/ReferenceData';

// TODO reporte correcto de errores de validación
function FieldError({ errors, id }) {
    if (!errors)
        return (<div/>)
    else if (isMobile)
        return (<div>⛔ Campo requerido</div>);
    else
        return (<Error id={id} error='⛔️' description='Campo requerido'/>);
}

FieldError.propTypes = {
    id: PropTypes.string.isRequired,
    errors: PropTypes.object
};

FieldError.defaultProps = {};

export default FieldError;
