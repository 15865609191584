import { reactSelectStyle } from "../../misc/ReactSelectStyle";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { useNoCacheAxios } from '../../misc/Net';
import Select from 'react-select';

function TaxSelectInput(props) {

    const { id, name, control, className, defaultLabel, rules, autoFocus, onChange: parentOnChange, readOnly } = props;

    const [{ data, loading }] = useNoCacheAxios("/api/taxes");

    return (
        <Controller
            render={({ onChange: controllerOnChange, value }) => {
                if (readOnly)
                    return <input
                        type="text"
                        id={id}
                        name={name}
                        className={className + " form-input"}
                        value={value?.name}
                        readOnly={true}
                        autoFocus={autoFocus}
                    />;
                else
                    return <Select
                        inputId={id}
                        className={className + " react-select"}
                        styles={reactSelectStyle}
                        value={value}
                        options={loading ? [] : data.data}
                        getOptionLabel={tax => tax.name}
                        getOptionValue={tax => tax}
                        placeholder={defaultLabel ?? "Seleccione impuesto"}
                        autoFocus={autoFocus}
                        menuPortalTarget={document.body}
                        menuPosition="fixed"
                        onChange={v => {
                            controllerOnChange(v);
                            parentOnChange(v);
                        }}
                    />;
            }}
            name={name}
            control={control}
            rules={rules}/>
    );
}

TaxSelectInput.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    control: PropTypes.object.isRequired,
    className: PropTypes.string,
    defaultLabel: PropTypes.string,
    rules: PropTypes.object,
    autoFocus: PropTypes.bool,
    onChange: PropTypes.func,
    readOnly: PropTypes.bool
};

TaxSelectInput.defaultProps = {
    className: "tax-select",
    rules: { required: true },
    autoFocus: false,
    onChange: () => {
    },
    readOnly: false
};

export { TaxSelectInput };
