import "./UserDialog.css";
import { useForm } from "react-hook-form";
import axios from "axios";
import handleError from "../../misc/ErrorHandling";
import { isId } from "../../misc/Values";
import { useEffect } from "react";
import TextInput from "../../components/form/TextInput";
import FieldError from "../../components/form/FieldError";
import PasswordInput from "../../components/form/PasswordInput";
import EmailInput from "../../components/form/EmailInput";
import { RESOURCE_SECURITY } from '../../misc/ReferenceData';

export function UserDialog({ userId, closeDialog, modal, onUserSaved, session }) {

    const { canWrite } = session;

    const readOnly = !canWrite(RESOURCE_SECURITY);

    const { control, register, handleSubmit, errors, setError, setValue } = useForm({
        defaultValues: {
            username: "",
            full_name: "",
            email: "",
            password: "",
            verify: "",
            "access_clients": "NONE",
            "access_subscriptions": "NONE",
            "access_hostings": "NONE",
            "access_hosting-plans": "NONE",
            "access_additional-items": "NONE",
            "access_works": "NONE",
            "access_invoices": "NONE",
            "access_settings": "NONE",
            "access_security": "NONE"
        }
    });

    useEffect(() => {
        if (isId(userId)) {
            let user = axios.get(`/api/users/${userId}`).then(response => response.data.data);
            let access = userId === 1
                ? Promise.resolve([])
                : axios.get(`/api/users/${userId}/access`).then(response => response.data.data);
            Promise.all([user, access])
                .then(([user, access]) => {
                    setValue("username", user.username);
                    setValue("full_name", user.full_name);
                    setValue("email", user.email);
                    Object.entries(access).forEach(([res, acc]) => setValue(`access_${res}`, acc));
                })
                .catch(error => handleError("Alta/modificación de usuario", error, modal));
        }
    }, [setValue, userId, modal]);

    const onSubmit = form => {
        if (form.password !== form.verify)
            setError("verify", { type: "mismatch", message: "La password y la verificación no coinciden" });
        else {
            const data = {
                username: form.username,
                full_name: form.full_name,
                email: form.email,
                ...form.password !== "" ? { password: form.password } : {}
            };
            const access = {
                "clients": form["access_clients"],
                "subscriptions": form["access_subscriptions"],
                "hostings": form["access_hostings"],
                "hosting-plans": form["access_hosting-plans"],
                "additional-items": form["access_additional-items"],
                "works": form["access_works"],
                "invoices": form["access_invoices"],
                "settings": form["access_settings"],
                "security": form["access_security"]
            };
            const save = isId(userId)
                ? axios.put(`/api/users/${userId}`, data)
                : axios.post(`/api/users`, data)
            save.then(response => response.data.data)
                .then(user => axios.put(`/api/users/${user.id}/access`, access))
                .then(() => {
                    onUserSaved();
                    closeDialog()
                })
                .catch(error => handleError("Alta/modificación de usuario", error, modal));
        }
    }

    const onCancel = () => {
        closeDialog();
    };

    return (
        <div id="new-user-details-container">
            <form onSubmit={handleSubmit(onSubmit)}>
                <section id="new-user-details" className={"form-grid " + (userId === 1 ? "root-user" : "")}>

                    <label className="form-label" htmlFor="username">Usuario</label>
                    <TextInput
                        id="username"
                        name="username"
                        control={control}
                        readOnly={userId === 1 || readOnly}
                        autoFocus={userId !== 1 && !readOnly}
                        rules={{
                            required: true,
                            pattern: /^[a-zA-Z]([.\-_]?[a-zA-Z0-9])*$/,
                            minLength: 2,
                            maxLength: 50
                        }}/>
                    <FieldError id="error-username" errors={errors.username}/>

                    <label className="form-label" htmlFor="full_name">Nombre</label>
                    <TextInput
                        id="full_name"
                        name="full_name"
                        control={control}
                        autoFocus={userId === 1 && !readOnly}
                        readOnly={readOnly}
                        rules={{ required: true, minLength: 2, maxLength: 100 }}/>
                    <FieldError id="error-full_name" errors={errors.full_name}/>

                    <label className="form-label" htmlFor="email">E-mail</label>
                    <EmailInput id="email" name="email" control={control} readOnly={readOnly}/>
                    <FieldError id="error-email" errors={errors.email}/>

                    {!readOnly &&
                    <>
                        <label className="form-label" htmlFor="password">Password</label>
                        <PasswordInput
                            id="password"
                            name="password"
                            control={control}
                            readOnly={readOnly}
                            rules={{ required: false, minLength: 5, maxLength: 100 }}/>
                        <FieldError id="error-password" errors={errors.password}/>

                        <label className="form-label" htmlFor="verify">Verificación</label>
                        <PasswordInput
                            id="verify"
                            name="verify"
                            control={control}
                            readOnly={readOnly}
                            rules={{ required: false, minLength: 5, maxLength: 100 }}/>
                        <FieldError id="error-verify" errors={errors.verify}/>
                    </>
                    }

                    {readOnly &&
                    <>
                        <span/><span/><span/>
                        <span/><span/><span/>
                    </>
                    }

                    {userId !== 1 && <UserAccess register={register} errors={errors} readOnly={readOnly}/>}

                    {!readOnly &&
                    <div className={"buttons " + (userId === 1 ? "root-user" : "")}>
                        <button className="save-new-user">Guardar</button>
                        <button className="cancel-edit" onClick={onCancel}>Cancelar</button>
                    </div>
                    }

                    {readOnly &&
                    <div className={"buttons " + (userId === 1 ? "root-user" : "")}>
                        <button className="cancel-edit" onClick={onCancel}>Cerrar</button>
                    </div>
                    }
                </section>
            </form>
        </div>
    );
}

function UserAccess({ register, errors, readOnly }) {
    return (
        <>
            <h3 className="user-access">Accesos</h3>
            <Access register={register} errors={errors} access="access_clients" label="Clientes" readOnly={readOnly}/>
            <Access register={register} errors={errors} access="access_subscriptions" label="Abonos" readOnly={readOnly}/>
            <Access register={register} errors={errors} access="access_hostings" label="Hostings" readOnly={readOnly}/>
            <Access register={register} errors={errors} access="access_hosting-plans" label="Planes Hosting" readOnly={readOnly}/>
            <Access register={register} errors={errors} access="access_additional-items" label="Adicionales" readOnly={readOnly}/>
            <Access register={register} errors={errors} access="access_works" label="Servicios" readOnly={readOnly}/>
            <Access register={register} errors={errors} access="access_invoices" label="Facturación" readOnly={readOnly}/>
            <Access register={register} errors={errors} access="access_settings" label="Settings Generales" readOnly={readOnly}/>
            <Access register={register} errors={errors} access="access_security" label="Seguridad" readOnly={readOnly}/>
        </>
    );
}

function Access({ access, label, register, errors, readOnly }) {
    let rules = { required: true };
    return (
        <>
            <label className="form-label" htmlFor={access}>{label}</label>
            <div className={`options-${access} options`}>
                <label className="radio-label">
                    Sin acceso
                    <input type="radio" name={access} className="form-input" value="NONE" ref={register(rules)} disabled={readOnly}/>
                    <span/>
                </label>
                <label className="radio-label">
                    Consulta
                    <input type="radio" name={access} className="form-input" value="READ" ref={register(rules)} disabled={readOnly}/>
                    <span/>
                </label>
                <label className="radio-label">
                    Modificación
                    <input type="radio" name={access} className="form-input" value="WRITE" ref={register(rules)} disabled={readOnly}/>
                    <span/>
                </label>
            </div>
            <FieldError id={`error-${access}`} errors={errors.access_subscriptions}/>
        </>
    );
}
