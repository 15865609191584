import { reactSelectStyle } from "../../misc/ReactSelectStyle";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import axios from "axios";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";

function IvaSelectInput(props) {

    const { id, name, control, className, defaultLabel, rules, autoFocus, onChange: parentOnChange, readOnly } = props;

    const findIvas = term =>
        axios.get("/api/iva", { params: { term, fields: "id,name" } })
            .then(response => response.data.data);

    return (
        <Controller
            render={({ onChange: controllerOnChange, value }) => {
                if (readOnly)
                    return <input
                        type="text"
                        id={id}
                        name={name}
                        className={className + " form-input"}
                        value={value?.name}
                        readOnly={true}
                        autoFocus={autoFocus}/>;
                else
                    return <AsyncSelect
                        inputId={id}
                        className={className + " react-select"}
                        styles={reactSelectStyle}
                        value={value}
                        defaultOptions={true}
                        loadOptions={findIvas}
                        cacheOptions={false}
                        getOptionLabel={le => le.name}
                        getOptionValue={le => le}
                        placeholder={defaultLabel ?? "Seleccione condición IVA"}
                        autoFocus={autoFocus}
                        menuPortalTarget={document.body}
                        menuPosition="fixed"
                        onChange={v => {
                            controllerOnChange(v);
                            parentOnChange(v);
                        }}/>;
            }}
            name={name}
            control={control}
            rules={rules}/>
    );
}

IvaSelectInput.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    control: PropTypes.object.isRequired,
    className: PropTypes.string,
    defaultLabel: PropTypes.string,
    rules: PropTypes.object,
    autoFocus: PropTypes.bool,
    onChange: PropTypes.func,
    readOnly: PropTypes.bool
};

IvaSelectInput.defaultProps = {
    className: "iva-select",
    rules: { required: true },
    autoFocus: false,
    onChange: () => {
    },
    readOnly: false
};

export { IvaSelectInput };
