import NumberFormat from "react-number-format";
import PropTypes from "prop-types";

function AmountDisplay({ amount, defaultValue, id, className, style, prefix }) {

    if (typeof(amount) === "number")
        return (
            <NumberFormat
                id={id}
                className={`amount ${className}`}
                style={style}
                displayType="text"
                decimalScale="2"
                fixedDecimalScale={true}
                thousandSeparator="."
                decimalSeparator=","
                prefix={prefix}
                value={amount}/>
        );
    else
        return (
            <span className={`amount default-value ${className}`} style={style}>{defaultValue}</span>
        );
}

AmountDisplay.propTypes = {
    amount: PropTypes.number,
    defaultValue: PropTypes.any,
    id: PropTypes.string,
    name: PropTypes.string,
    style: PropTypes.object,
    prefix: PropTypes.string
};

AmountDisplay.defaultProps = {
    defaultValue: 0,
    className: "",
    style: {
        fontFamily: "monospace"
    }
}

export default AmountDisplay
