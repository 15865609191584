import "./Session.css";
import axios from "axios";
import { useModal } from "./Modal";
import { useForm } from "react-hook-form";
import logo from "../logo-wh.png";
import logoSmall from "../logo-wh-small.png";
import { createContext } from "react";
import FieldError from "./form/FieldError";
import PropTypes from 'prop-types';
import { useNoCacheAxios } from '../misc/Net';

export const SessionContext = createContext({
    username: "anónimo",
    canRead: () => false,
    canWrite: () => false,
    logout: () => {
    }
});

// TODO control de vencimiento de la sesión

function canRead(resource) {
    return this[resource] === "READ" || this[resource] === "WRITE";
}

function canWrite(resource) {
    return this[resource] === "WRITE";
}

function Session({ setModule, children }) {

    const { register, handleSubmit, errors } = useForm({});
    const [{ data: session, error, loading }, refetch] = useNoCacheAxios("/api/session");
    const modal = useModal();

    const onSubmit = form => {
        const data = {
            username: form.username,
            password: form.password
        };
        axios.put(`/api/session`, data)
            .then(() => refetch())
            .catch(error => {
                if (error.response.status === 401)
                    modal({ title: "Ingreso", message: error.response.data.errors[0] });
                else
                    modal({ title: "Ocurrió un error", message: error.message });
                //.finally(() => setFocus("username")); // TODO migrar a react-hook-form v7 (ojo: rompe los selects)
            });
    };

    const logout = () => {
        axios.delete(`/api/session`)
            .then(() => refetch())
            .catch(err => console.log(err))
            .finally(() => setModule("home"));
    };

    if (loading)
        return (
            <section id="system-loading">
                <span>Un momento...</span>
            </section>
        );
    else if (error && error.response.status === 401)
        return (
            <div id="login-container">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <section id="login">
                        <img id="login-logo" src={logo} alt="DMCFull logo"/>
                        <div id="form-container">
                            <label htmlFor="username">Usuario</label>
                            <input
                                type="text"
                                className="form-input"
                                id="username"
                                name="username"
                                autoFocus
                                ref={register({ required: true })}/>
                            <FieldError id="username" errors={errors.username}/>
                            <label htmlFor="user-password">Password</label>
                            <input
                                type="password"
                                className="form-input"
                                id="user-password"
                                name="password"
                                ref={register({ required: true })}/>
                            <FieldError id="password" errors={errors.username}/>
                            <button id="login-button">Entrar</button>
                        </div>
                    </section>
                </form>
            </div>
        );
    else if (error || session?.data?.username === undefined)
        return (
            <section id="system-unavailable">
                <span>El sistema no se encuentra disponible.</span>
            </section>
        );
    else
        return (
            <>
                <section id="session">
                    <img id="logo" src={logoSmall} alt="DMCFull logo"/>
                </section>
                <SessionContext.Provider value={{
                    username: session.data.username,
                    canRead: canRead.bind(session.data.access),
                    canWrite: canWrite.bind(session.data.access),
                    logout
                }}>
                    {children}
                </SessionContext.Provider>
            </>
        );
}

Session.propTypes = {
    setModule: PropTypes.func.isRequired,
    children: PropTypes.node
};

Session.defaultProps = {
};

export default Session;
