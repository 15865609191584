import { reactSelectStyle } from "../../misc/ReactSelectStyle";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import Select from "react-select";
import { useNoCacheAxios } from '../../misc/Net';

function HostingPlanSelectInput(props) {

    const { id, name, control, className, defaultLabel, rules, autoFocus, onChange: onParentChange, readOnly } = props;

    const [{ data, loading }] = useNoCacheAxios("/api/hosting-plans");

    return (
        <Controller
            render={({ onChange: onControllerChange, value }) => {
                if (readOnly)
                    return <input
                        type="text"
                        id={id}
                        name={name}
                        className={className + " form-input"}
                        value={value?.name}
                        readOnly={true}
                        autoFocus={autoFocus}/>;
                else
                    return <Select
                        className={className + " react-select"}
                        inputId={id}
                        styles={reactSelectStyle}
                        value={value}
                        options={loading ? [] : data.data}
                        getOptionLabel={hostingPlan => hostingPlan.name}
                        getOptionValue={hostingPlan => hostingPlan}
                        placeholder={defaultLabel ?? "Seleccione plan hosting"}
                        autoFocus={autoFocus}
                        menuPortalTarget={document.body}
                        menuPosition="fixed"
                        onChange={v => {
                            onControllerChange(v);
                            onParentChange(v);
                        }}/>;
            }}
            name={name}
            control={control}
            rules={rules}/>
    );
}

HostingPlanSelectInput.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    control: PropTypes.object.isRequired,
    className: PropTypes.string,
    defaultLabel: PropTypes.string,
    rules: PropTypes.object,
    autoFocus: PropTypes.bool,
    onChange: PropTypes.func,
    readOnly: PropTypes.bool
};

HostingPlanSelectInput.defaultProps = {
    className: "client-select",
    rules: { required: true },
    autoFocus: false,
    onChange: () => {
    },
    readOnly: false
};

export { HostingPlanSelectInput };
