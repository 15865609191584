import {
    invoice_item_types,
    ITEM_TYPE_ADDITIONAL_ITEM,
    ITEM_TYPE_HOSTING,
    ITEM_TYPE_SERVICE
} from '../../misc/ReferenceData';
import NumberFormat from 'react-number-format';
import { tableStyles } from './Common';
import AmountDisplay from '../../components/form/AmountDisplay';

export function InvoiceItems({ invoice }) {

    let rows;
    if (!invoice)
        rows = (
            <tr>
                <td colSpan={3}>Cargando...</td>
            </tr>
        );
    else if (invoice.data.charges.length === 0)
        rows = (
            <tr>
                <td colSpan={3}>No hay cargos 🤨</td>
            </tr>
        );
    else
        rows = invoice.data.charges.map((charge, index) => {
            let isAdditional = invoice_item_types[charge.type] === ITEM_TYPE_ADDITIONAL_ITEM;
            let description;
            switch (invoice_item_types[charge.type]) {
                case ITEM_TYPE_HOSTING:
                    description = `Hosting ${charge.description} - ${charge.site ?? "N/A"} - ${charge.invoice_period_detail ?? "N/A"}`;
                    break;
                case ITEM_TYPE_SERVICE:
                    description = charge.date + ': ' + charge.description;
                    break;
                default:
                    description = charge.description;
            }
            return (<tr key={index}>
                <td style={tableStyles.typeCell}>{invoice_item_types[charge.type]}</td>
                <td style={tableStyles.hours}>
                    <NumberFormat
                        displayType="text"
                        fixedDecimalScale={true}
                        value={charge.hours}
                        defaultValue={1}
                        style={tableStyles.amountValue}
                    />
                </td>
                <td style={tableStyles.cell} colSpan={isAdditional ? 1 : 2}>{description}</td>
                {isAdditional &&
                    <td style={tableStyles.taxCell}>
                        <span style={tableStyles.taxCode}>{charge.tax_code}</span>
                        <NumberFormat
                            displayType="text"
                            fixedDecimalScale={true}
                            value={(charge.tax_rate ?? 0) * 100}
                            decimalScale={2}
                            style={tableStyles.taxRate}
                            defaultValue={0}
                            suffix="%"/>
                    </td>
                }
                <td style={tableStyles.amountCell}>
                    <AmountDisplay amount={charge.unit_price ?? charge.amount} style={tableStyles.amountValue}/>
                </td>
                <td style={tableStyles.amountCell}>
                    <AmountDisplay amount={charge.total_price ?? charge.amount} style={tableStyles.amountValue}/>
                </td>
            </tr>)
        });

    return (
        <table style={tableStyles.table}>
            <thead>
            <tr>
                <th style={tableStyles.typeCell}>Tipo</th>
                <th style={tableStyles.cell} colSpan={3}>Concepto</th>
                <th style={tableStyles.cell}>Precio Unit.</th>
                <th style={tableStyles.cell}>Total</th>
            </tr>
            </thead>
            <tbody>
            {rows}
            </tbody>
            {invoice &&
            <tfoot>
            <tr>
                <th style={tableStyles.cell} colSpan={5}>Total</th>
                <td style={tableStyles.amountCell}>
                    <AmountDisplay amount={invoice.data.total} style={tableStyles.amountValue}/>
                </td>
            </tr>
            </tfoot>
            }
        </table>
    );
}
