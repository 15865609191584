import "./AdditionalItemsTable.css";
import Command from "../../components/Command";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Amount, isNumber, Percentage } from "../../misc/Values";
import AmountDisplay from '../../components/form/AmountDisplay';
import { CellTaxSelect } from '../../components/form/CellTaxSelect';
import { useEffect, useState } from 'react';
import { useFieldArray, useWatch } from 'react-hook-form';
import PropTypes from 'prop-types';
import AmountInput from '../../components/form/AmountInput';

function AdditionalItemsTable(props) {

    const { id, name, control, register, defaultValues } = props;

    const { fields, append, remove } = useFieldArray({ control, name });

    const items = useWatch({ control, name: "items" });

    useEffect(() => {
        if ((items?? []).length === 0) {
            append({}, false);
        }
    }, [items, append]);

    const [focus, setFocus] = useState(false);

    const itemTotal = index => {
        let item = (items ?? [])[index];
        if (item && item.quantity && item.unit_price && isNumber(item.tax?.tax)) {
            const qty = new Amount(item.quantity);
            const unitPrice = new Amount(item.unit_price);
            const tax = new Percentage(item.tax?.tax);
            return unitPrice.mul(qty).mul(tax.plus(1)).toNumber();
        }
        return null;
    };

    const rows = fields.map((item, index) => {
        return (
            <div key={item.id} className="table-row">
                <span className="table-cell clickable">
                    <input
                        type="number"
                        className="cell-edit"
                        name={`${name}[${index}].quantity`}
                        ref={register({ required: true, min: 1 })}
                        autoFocus={index === fields.length - 1 && focus}
                        min={1}
                        defaultValue={item.quantity}
                    />
                </span>
                <span className="table-cell clickable">
                    <input
                        type="text"
                        className="cell-edit"
                        name={`${name}[${index}].description`}
                        ref={register({ required: true })}
                        defaultValue={item.description}
                    />
                </span>
                <span className="table-cell clickable amount">
                    <AmountInput
                        name={`${name}[${index}].unit_price`}
                        control={control}
                        allowZero={false}
                        className="cell-edit"
                        baseClassName=" amount"
                    />
                </span>
                <span className="table-cell clickable">
                    <CellTaxSelect
                        name={`${name}[${index}].tax`}
                        control={control}
                    />
                </span>
                <span className="table-cell clickable amount">
                    <AmountDisplay
                        amount={itemTotal(index)}
                        defaultValue={0}
                        className="form-input total"
                        prefix="$ "
                    />
                </span>
                <span className="table-cell">
                    {index === 0
                        ? <>&nbsp;</>
                        : <Command onClick={() => remove(index)}><FontAwesomeIcon icon="trash-alt"/></Command>
                    }
                </span>
            </div>
        );
    });

    const addItemAndFocus = () => {
        append(defaultValues());
        setFocus(true);
    };

    const footer = (
        <div className="table-row">
            <span className="table-cell footer"/>
            <span className="table-cell footer"/>
            <span className="table-cell footer"/>
            <span className="table-cell footer"/>
            <span className="table-cell footer"/>
            <span className="table-cell footer">
                <Command onClick={addItemAndFocus}>
                    <FontAwesomeIcon icon="plus-circle"/>
                </Command>
            </span>
        </div>
    );

    return (
        <div id={id}>
            <div className="table-row">
                <span className="table-header">Cant</span>
                <span className="table-header">Concepto</span>
                <span className="table-header">Importe</span>
                <span className="table-header">Imp</span>
                <span className="table-header">Total</span>
                <span className="table-header">&nbsp;</span>
            </div>
            {rows}
            {footer}
        </div>
    );
}

AdditionalItemsTable.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    control: PropTypes.object.isRequired,
    register: PropTypes.func.isRequired,
    defaultValues: PropTypes.func.isRequired,
    onChange: PropTypes.func
};

AdditionalItemsTable.defaultProps = {
    onChange: () => {
    }
};

export default AdditionalItemsTable;
