import NumberFormat from "react-number-format";
import { Controller } from "react-hook-form";
import PropTypes from "prop-types";
import { isMobile } from '../../misc/ReferenceData';

function AmountInput({ id, name, control, className, baseClassName, rules, autoFocus, allowZero, readOnly, onChange: parentOnChange }) {

    return (
        <Controller
            render={({ onChange: controllerOnChange, value, name }) => (
                <NumberFormat
                    type={isMobile ? "tel" : "text"}
                    allowNegative={false}
                    decimalScale="2"
                    fixedDecimalScale={true}
                    decimalSeparator=","
                    thousandSeparator="."
                    onValueChange={(v) => {
                        controllerOnChange(v.value);
                        parentOnChange(v.value);
                    }}
                    prefix="$ "
                    value={value?.value ?? value ?? ""}
                    isNumericString={true}
                    name={name}
                    id={id}
                    className={className + " " + baseClassName}
                    readOnly={readOnly}
                    autoFocus={autoFocus}
                    onFocus={ev => ev.target.select()}/>
            )}
            defaultValue={null}
            name={name}
            control={control}
            rules={{ ...rules, ...(allowZero ? {} : { min: 0.10 }) }}/>
    )
}

AmountInput.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    control: PropTypes.object.isRequired,
    className: PropTypes.string,
    baseClassName: PropTypes.string,
    rules: PropTypes.object,
    autoFocus: PropTypes.bool,
    allowZero: PropTypes.bool,
    readOnly: PropTypes.bool
};

AmountInput.defaultProps = {
    className: "",
    rules: { required: true, min: 0, max: 999999999, pattern: /^[0-9]{1,18}(\.[0-9]{1,2})?$/, valueAsNumber: true },
    baseClassName: "form-input amount",
    autoFocus: false,
    allowZero: true,
    readOnly: false,
    onChange: () => {}
};

export default AmountInput;
