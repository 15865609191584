import "./PeriodSelect.css";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import { currentPeriod } from "../../misc/Values";
import { createRef } from "react";

function PeriodSelect({ id, className, defaultLabel, period, onSelect, defaultPeriod, defaultPeriodLabel }) {

    const ref = createRef();

    const onDefaultClick = () => {
        ref.current?.setOpen(false);
        onSelect(defaultPeriod);
    };

    return (
        <DatePicker
            id={id}
            className="form-input"
            calendarClassName={className}
            selected={period ? new Date(period.year, period.month - 1, 1) : new Date()}
            onChange={date => onSelect({ month: date.getMonth() + 1, year: date.getFullYear() })}
            dateFormat="MMMM yyyy"
            showMonthYearPicker
            showFullMonthYearPicker
            placeholderText={defaultLabel ?? "Seleccione período"}
            withPortal
            ref={ref}>
            <div className="extra-buttons">
                <button onClick={onDefaultClick}>{defaultPeriodLabel}</button>
            </div>
        </DatePicker>
    );
}

PeriodSelect.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    className: PropTypes.string,
    defaultLabel: PropTypes.string,
    onSelect: PropTypes.func,
    defaultPeriod: PropTypes.object,
    defaultPeriodLabel: PropTypes.string
};

PeriodSelect.defaultProps = {
    className: "period-selector",
    onSelect: () => {
    },
    defaultPeriod: currentPeriod,
    defaultPeriodLabel: "Período actual"
};

export { PeriodSelect };
