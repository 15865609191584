import "./Works.css";
import axios from "axios";
import { useModal } from "../../components/Modal";
import Command from "../../components/Command";
import Work from "../works/Work";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import handleError from "../../misc/ErrorHandling";
import { useContext, useState } from "react";
import { ClientSelector } from "../../components/form/ClientSelect";
import { currentPeriod, formatDate } from "../../misc/Values";
import { SessionContext } from "../../components/Session";
import { RESOURCE_WORKS } from '../../misc/ReferenceData';
import { useNoCacheAxios } from '../../misc/Net';
import AmountDisplay from '../../components/form/AmountDisplay';
import { PeriodSelect } from '../../components/form/PeriodSelect';

function Works() {

    const session = useContext(SessionContext);

    const { canWrite } = session;

    const readOnly = !canWrite(RESOURCE_WORKS);

    const [clientId, setClientId] = useState("");

    const [period, setPeriod] = useState(currentPeriod);

    const modal = useModal();

    const [{ data: works, loading }, getWorks] =
        useNoCacheAxios(`/api/works?client_id=${clientId}&month=${period.month}&year=${period.year}&aggregate=total`);

    const onClientSelect = (selectedClient) =>
        setClientId(selectedClient.id);

    const onWorkSaved = () =>
        getWorks()
            .catch(error => handleError("Work", error, modal));

    const onGetWorks = () =>
        getWorks()
            .catch(error => handleError("Work", error, modal));

    const onWorkSelect = (workId) => () =>
        modal({
            title: readOnly ? "Detalle servicio" : "Modificación servicio",
            style: "dialog",
            component: onClose =>
                <Work workId={workId}
                      closeDialog={onClose}
                      modal={modal}
                      onWorkSaved={onWorkSaved}
                      session={session}/>
        });

    const onWorkDelete = workId => () =>
        modal({ title: "Servicio", message: "Confirme que desea eliminar el servicio", confirm: true })
            .then(() => axios.delete(`/api/works/${workId}`))
            .then(() => getWorks())
            .catch(error => handleError("Eliminación de servicio", error, modal));

    let rows;
    if (loading || !works)
        rows = (
            <div className="table-row">
                <span className="table-cell-whole-row">Cargando...</span>
            </div>
        );
    else if (works.data.works.length === 0)
        rows = (
            <div className="table-row">
                <span className="table-cell-whole-row">No hay servicios</span>
            </div>
        );
    else
        rows = works.data.works.map(work => {
            return (
                <div key={work.id} className="table-row">
                    <span className="table-cell clickable"
                          onClick={onWorkSelect(work.id)}>{formatDate(work.date)}</span>
                    <span className="table-cell clickable" onClick={onWorkSelect(work.id)}>{work.client_name}</span>
                    <span className="table-cell clickable" onClick={onWorkSelect(work.id)}>{work.description}</span>
                    <span className="table-cell clickable" onClick={onWorkSelect(work.id)}>{work.hours}</span>
                    <span className="table-cell clickable" onClick={onWorkSelect(work.id)}>{work.monthly_invoice ? "No" : "Si"}</span>
                    <span className="table-cell">
                        {/* TODO deshabilitar si ya está facturado */}
                        <Command onClick={onWorkDelete(work.id)} disabled={readOnly}>
                            <FontAwesomeIcon icon="trash-alt"/>
                        </Command>
                    </span>
                </div>
            );
        });

    return (
        <div id="works-container">
            <div id="works-title-container" className="form-grid">
                <h2 id="works-title">
                    Planilla de Servicios
                    <Command onClick={onGetWorks}><FontAwesomeIcon icon="sync-alt"/></Command>
                </h2>
            </div>
            <div id="filters-container" className="form-grid">
                <label className="form-label" htmlFor="client-selector">Cliente</label>
                <ClientSelector id="client-selector" defaultLabel="Todos los clientes" onSelect={onClientSelect}/>
                <span/>
                <label className="form-label" htmlFor="month">Período</label>
                <PeriodSelect
                    id="period"
                    period={period}
                    defaultPeriod={currentPeriod}
                    defaultPeriodLabel="Período actual"
                    onSelect={setPeriod}/>
                <span/>
                <label className="form-label">Importe a Facturar</label>
                <AmountDisplay amount={loading ? 0 : works.data.total} prefix="$ " className="form-input total"/>
                <span/>
            </div>
            <div id="works">
                <div className="table-row">
                    <span className="table-header">Fecha</span>
                    <span className="table-header">Cliente</span>
                    <span className="table-header">Concepto</span>
                    <span className="table-header">Horas</span>
                    <span className="table-header"><abbr title="Facturación inmediata">F. Inm.</abbr></span>
                    <span className="table-header">Eliminar</span>
                </div>
                {rows}
            </div>
        </div>
    );
}

export default Works;
