import "./AdditionalItem.css";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useModal } from "../../components/Modal";
import { ClientSelectInput } from "../../components/form/ClientSelect";
import AmountInput from "../../components/form/AmountInput";
import DescriptionInput from "../../components/form/DescriptionInput";
import DateInput from "../../components/form/DateInput";
import handleError from "../../misc/ErrorHandling";
import FieldError from "../../components/form/FieldError";
import { formatISO } from "date-fns";
import { LegalEntitySelectInput } from "../../components/form/LegalEntitySelect";
import PropTypes from "prop-types";
import { Amount, isId, isNumber, Percentage } from "../../misc/Values";
import { useContext, useEffect, useState } from "react";
import { isMobile, RESOURCE_ADDITIONAL_ITEMS } from '../../misc/ReferenceData';
import { SessionContext } from '../../components/Session';
import AdditionalItemsTable from './AdditionalItemsTable';
import { TaxSelectInput } from '../../components/form/TaxSelect';
import AmountDisplay from '../../components/form/AmountDisplay';

function AdditionalItem(props) {

    const { additionalItemId, closeDialog, modal: parentModal, onAdditionalItemSaved, session: parentSession } = props;

    const ownSession = useContext(SessionContext);

    const { canWrite } = parentSession ?? ownSession;

    const readOnly = !canWrite(RESOURCE_ADDITIONAL_ITEMS);

    const [additionalItem, setAdditionalItem] = useState();

    const [quantity, setQuantity] = useState();

    const [unitPrice, setUnitPrice] = useState();

    const [tax, setTax] = useState();

    const [legalEntity, setLegalEntity] = useState();

    const invoiced = additionalItem && additionalItem.invoice_id;

    const ownModal = useModal();

    const modal = parentModal ?? ownModal;

    const operation = isId(additionalItemId) ? "Modificación" : "Alta";

    const multiItemInput = !isId(additionalItemId) && !isMobile;

    const baseDefaultValues = {
        client: null,
        date: formatISO(new Date(), { representation: "date" }),
        invoice_issuer: null,
        immediate_invoice: false
    };

    const defaultValues = () => {
        let defaultsForMultiInput = { ...baseDefaultValues, items: [] };
        let defaultsForSingleInput = { ...baseDefaultValues, ...defaultItemValues() };
        return multiItemInput ? defaultsForMultiInput : defaultsForSingleInput;
    };

    const defaultItemValues = () => ({});

    const { register, handleSubmit, errors, reset, control, setValue, clearErrors, setError } =
        useForm({ defaultValues: defaultValues() });

    const onClientSelect = client =>
        axios.get(`/api/clients/${client.id}`)
            .then(response => response.data.data)
            .then(client => axios.get(`/api/legal-entities/${client.invoice_issuer}`))
            .then(response => response.data.data)
            .then(legalEntity => {
                legalEntity.name = legalEntity.name + " (preferido)";
                setLegalEntity(legalEntity);
                setValue("invoice_issuer", legalEntity);
            })
            .catch(error => handleError("Auto-selección de emisor de factura", error, modal));

    useEffect(() => {
        if (isId(additionalItemId)) {
            axios.get(`/api/additional-items/${additionalItemId}`)
                .then(response => response.data.data)
                .then(additionalItem => {
                    setValue("client", { id: additionalItem.client_id, name: additionalItem.client_name });
                    setValue("date", additionalItem.date);
                    setValue("quantity", additionalItem.quantity);
                    setQuantity(additionalItem.quantity);
                    setValue("description", additionalItem.description);
                    setValue("unit_price", additionalItem.unit_price);
                    setUnitPrice(additionalItem.unit_price);
                    setValue("tax_id", additionalItem.tax_id);
                    setValue("invoice_issuer", {
                        id: additionalItem.invoice_issuer,
                        name: additionalItem.legal_entity_name
                    });
                    setValue("immediate_invoice", !additionalItem.monthly_invoice);
                    setAdditionalItem(additionalItem);
                    return axios(`/api/taxes/${additionalItem.tax_id}`)
                })
                .then(tax => tax.data.data)
                .then(tax => {
                    setValue("tax", tax);
                    setTax(tax);
                })
                .catch(error => handleError(`${operation} de ítem adicional`, error, modal));
        }
    }, [setValue, additionalItemId, modal, operation, setUnitPrice, setAdditionalItem, setTax]);

    let totalPrice;
    if (quantity && unitPrice && isNumber(tax?.tax)) {
        const qty = new Amount(quantity);
        const up = new Amount(unitPrice);
        const t = new Percentage(tax.tax);
        totalPrice = up.mul(qty).mul(t.plus(1)).toNumber();
    } else {
        totalPrice = 0;
    }

    const onSubmit = form => {
        const data = {
            client_id: form.client.id,
            date: form.date,
            description: form.description,
            invoice_issuer: form.invoice_issuer.id,
            monthly_invoice: !form.immediate_invoice
        };
        if (multiItemInput) {
            let items = form.items ?? [];
            if (items.length === 0) {
                setError("items[0].quantity", { message: "Se debe indicar al menos un ítem" });
                return;
            }
            data.items = form.items.map(item => ({
                quantity: item.quantity,
                description: item.description,
                unit_price: item.unit_price,
                tax_id: item.tax.id
            }));
        } else {
            data.quantity = form.quantity;
            data.description = form.description;
            data.unit_price = form.unit_price;
            data.tax_id = form.tax.id;
        }
        const save = isId(additionalItemId)
            ? axios.put(`/api/additional-items/${additionalItemId}`, data)
                .then(() => {
                    onAdditionalItemSaved();
                    closeDialog();
                })
            : axios.post(`/api/additional-items`, data)
                .then(() => modal({ title: "Adicionales", message: "Adicional agregado" }))
                .then(() => reset(defaultValues()))
                .then(() => clearErrors())
        save.catch(error => handleError(`${operation} de ítem adicional`, error, modal));
    };

    const onCancel = () => {
        closeDialog();
    };

    return (
        <div id="additional-item-container">
            {!additionalItemId &&
            <div id="additional-item-title-container" className="form-grid">
                <h2 id="additional-item-title">Carga Adicionales</h2>
            </div>
            }
            <div id="additional-item-details-container">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <section id="additional-item-details" className={"form-grid " + (isId(additionalItemId) || isMobile ? "single-item" : "multi-item")}>
                        <label className="form-label" htmlFor="date">Fecha</label>
                        <DateInput control={control} name="date" id="date" readOnly={readOnly}/>
                        <FieldError id="error-date" errors={errors.date}/>
                        <label className="form-label" htmlFor="client-select">Cliente</label>
                        <ClientSelectInput
                            id="client-select"
                            name="client"
                            control={control}
                            onChange={onClientSelect}
                            autoFocus={!readOnly}
                            readOnly={readOnly}/>
                        <FieldError id="error-client" errors={errors.client}/>

                        {
                            isId(additionalItemId) || isMobile
                            ? <>
                                <label className="form-label" htmlFor="quantity">Cantidad</label>
                                {/*TODO usar "number" solo en mobile, mover a componente*/}
                                <input type="number"
                                       id="quantity"
                                       name="quantity"
                                       className="form-input"
                                       min={1}
                                       onChange={ev => setQuantity(ev.target.value)}
                                       readOnly={readOnly}
                                       ref={register({ required: true, min: 1 })}/>
                                <FieldError id="error-quantity" errors={errors.quantity}/>

                                <label className="form-label" htmlFor="description">Concepto</label>
                                <DescriptionInput id="description" name="description" register={register} readOnly={readOnly}/>
                                <FieldError id="error-description" errors={errors.description}/>

                                <label className="form-label" htmlFor="unit_price">Importe</label>
                                <AmountInput
                                    control={control}
                                    name="unit_price"
                                    id="unit_price"
                                    allowZero={false}
                                    onChange={v => setUnitPrice(v)}
                                    readOnly={readOnly}
                                />
                                <FieldError id="error-unit_price" errors={errors.unit_price}/>

                                <label className="form-label" htmlFor="tax">Impuesto</label>
                                <TaxSelectInput
                                    control={control}
                                    name="tax"
                                    id="tax"
                                    onChange={setTax}
                                />
                                <FieldError id="error-tax" errors={errors.tax}/>

                                <label className="form-label" htmlFor="total">Total</label>
                                <AmountDisplay
                                    amount={totalPrice}
                                    defaultValue="…"
                                    className="form-input total"
                                    prefix="$ "
                                />
                                <span/>
                            </>
                            : <>
                                <span/>
                                <AdditionalItemsTable
                                    id="multi-items"
                                    name="items"
                                    control={control}
                                    register={register}
                                    defaultValues={defaultItemValues}
                                />

                                <FieldError id="error-items" errors={errors.items}/>
                            </>
                        }

                        <label className="form-label" htmlFor="invoice_issuer">Emisor Factura</label>
                        <LegalEntitySelectInput
                            name="invoice_issuer"
                            id="invoice_issuer"
                            control={control}
                            readOnly={readOnly}
                            preferred={legalEntity?.id}/>
                        <FieldError id="error-invoice_issuer" errors={errors.invoice_issuer}/>
                        <label className="form-label" htmlFor="immediate_invoice">Facturación inmediata</label>
                        <span className="checkbox checkbox-immediate_invoice">
                            <input
                                name="immediate_invoice"
                                type="checkbox"
                                id="immediate_invoice"
                                className="form-input stand-alone"
                                disabled={readOnly}
                                ref={register()}/>
                            <label className="form-label" htmlFor="immediate_invoice"/>
                        </span>
                        <span/>

                        {!readOnly &&
                        <div className="buttons">
                            <button
                                className="save-additional-item"
                                disabled={(isId(additionalItemId) && !additionalItem) || invoiced}
                                title={invoiced && "Ya facturado"}>
                                Guardar
                            </button>
                            {isId(additionalItemId) && <button className="cancel" onClick={onCancel}>Cancelar</button>}
                        </div>
                        }

                        {readOnly &&
                        <div className="buttons">
                            <button className="cancel" onClick={onCancel}>Cerrar</button>
                        </div>
                        }
                    </section>
                </form>
            </div>
        </div>
    );
}

AdditionalItem.propTypes = {
    additionalItemId: PropTypes.number,
    closeDialog: PropTypes.func,
    modal: PropTypes.func,
    onAdditionalItemSaved: PropTypes.func
};

AdditionalItem.defaultProps = {
    additionalItemId: null,
    closeDialog: () => {
    },
    modal: null,
    onAdditionalItemSaved: () => {
    }
};

export default AdditionalItem;
