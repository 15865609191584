import "./InvoicingForm.css";
import { useForm } from "react-hook-form";
import { useModal } from "../../components/Modal";
import { useCallback, useContext, useEffect } from "react";
import axios from "axios";
import AmountInput from "../../components/form/AmountInput";
import FieldError from "../../components/form/FieldError";
import handleError from "../../misc/ErrorHandling";
import { SessionContext } from '../../components/Session';
import { RESOURCE_SETTINGS } from '../../misc/ReferenceData';

export function InvoicingForm() {

    const { canWrite } = useContext(SessionContext);

    const readOnly = !canWrite(RESOURCE_SETTINGS);

    const { control, handleSubmit, errors, setValue } = useForm({
        defaultValues: {
            tech_support_hourly_rate: "",
            vcpu_rate: "",
            ram_gb_rate: "",
            disk_rate: ""
        }
    });

    const modal = useModal();

    const fillForm = useCallback(
        data => {
            setValue("tech_support_hourly_rate", data.tech_support_hourly_rate);
            setValue("vcpu_rate", data.vcpu_rate);
            setValue("ram_gb_rate", data.ram_gb_rate);
            setValue("disk_rate", data.disk_rate);
        },
        [setValue]
    );

    const onSubmit = form => {
        const data = {
            tech_support_hourly_rate: form.tech_support_hourly_rate,
            vcpu_rate: form.vcpu_rate,
            ram_gb_rate: form.ram_gb_rate,
            disk_rate: form.disk_rate
        };
        axios.patch(`/api/system/invoicing-parameters`, data)
            .then(response => response.data.data)
            .then(data => fillForm(data))
            .then(() => modal({ title: "Facturación", message: "Datos guardados" }))
            .catch(error => handleError("Facturación", error, modal));
    };

    useEffect(() => {
        axios.get(`/api/system/invoicing-parameters`)
            .then(response => response.data.data)
            .then(data => fillForm(data))
    }, [fillForm]);

    return (
        <>
            <h3 id="settings-invoicing-title">Facturación</h3>
            <div id="settings-invoicing-container">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <section id="settings-invoicing" className="form-grid">

                        <label className="form-label" htmlFor="tech_support_hourly_rate">Valor hora técnica</label>
                        <AmountInput control={control} name="tech_support_hourly_rate" id="tech_support_hourly_rate" readOnly={readOnly}/>
                        <FieldError id="tech_support_hourly_rate" errors={errors.tech_support_hourly_rate}/>

                        <label className="form-label" htmlFor="vcpu_rate">Valor VCPU</label>
                        <AmountInput control={control} name="vcpu_rate" id="vcpu_rate" readOnly={readOnly}/>
                        <FieldError id="vcpu_rate" errors={errors.vcpu_rate}/>

                        <label className="form-label" htmlFor="ram_gb_rate">Valor GB RAM</label>
                        <AmountInput control={control} name="ram_gb_rate" id="ram_gb_rate" readOnly={readOnly}/>
                        <FieldError id="ram_gb_rate" errors={errors.ram_gb_rate}/>

                        <label className="form-label" htmlFor="disk_rate">Valor HDD</label>
                        <AmountInput control={control} name="disk_rate" id="disk_rate" readOnly={readOnly}/>
                        <FieldError id="disk_rate" errors={errors.disk_rate}/>

                        {!readOnly &&
                        <div className="buttons">
                            <button className="save-invoicing-settings">Guardar</button>
                        </div>
                        }
                    </section>
                </form>
            </div>
        </>
    );
}
