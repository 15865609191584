import "./Invoice.css";
import { RESOURCE_INVOICES } from "../../misc/ReferenceData";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import handleError from "../../misc/ErrorHandling";
import { useRef } from 'react';
import { useNoCacheAxios } from '../../misc/Net';
import { InvoiceHeader } from './InvoiceHeader';
import { InvoiceItems } from './InvoiceItems';

function Invoice(props) {

    const { legalEntityId, clientId, invoiceId, closeDialog, modal, onInvoiceDelete, session, invoiceData } = props;

    const { canWrite } = session;

    const readOnly = !canWrite(RESOURCE_INVOICES) || !invoiceId;

    const [{ data: invoiceGet }] =
        useNoCacheAxios(
            { url: `/api/invoices/${invoiceId}` },
            { manual: !invoiceId }
        );

    const invoice = invoiceGet ?? invoiceData;

    const [{ data: client, loading: loadingClient }] = useNoCacheAxios(`/api/clients/${clientId}`);

    const [{ data: iva }] = useNoCacheAxios(
        { url: `/api/iva/${client?.data.iva_id}` },
        { manual: loadingClient }
    );

    const [{ data: legalEntity }] = useNoCacheAxios(`/api/legal-entities/${legalEntityId}`);

    const cancelInvoice = () => {
        axios.delete(`/api/invoices/${invoiceId}`)
            .then(() => onInvoiceDelete())
            .then(() => closeDialog())
            .catch(error => handleError("Eliminación de factura", error, modal));
    }

    const onCancel = () => {
        closeDialog();
    };

    const onCopy = () => {
        if (invoiceItemsTable.current) {
            let range = document.createRange();
            range.selectNode(invoiceItemsTable.current)
            let selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
            document.execCommand("copy");
        }
    };

    const invoiceItemsTable = useRef(null);

    return (
        <div id="invoice-details">

            <table style={{ borderCollapse: "collapse" }}>
                <thead>
                <tr>
                    <td>
                        <InvoiceHeader client={client} iva={iva} invoice={invoice} legalEntity={legalEntity}/>
                    </td>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>
                        <div ref={invoiceItemsTable}>
                            <InvoiceItems invoice={invoice}/>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>

            <div className="form-grid">
                {!readOnly &&
                <div className="buttons">
                    <button className="cancel" onClick={onCancel}>Cerrar</button>

                    <div className="stretch"/>
                    {!readOnly && <button className="save" onClick={cancelInvoice}>Anular factura</button>}
                    {/*{invoiceId &&*/}
                    {/*<a className="download" href={`/api/invoices/${invoiceId}/spreadsheet`}>*/}
                    {/*    <FontAwesomeIcon icon="download" title="Planilla"/>*/}
                    {/*</a>*/}
                    {/*}*/}
                    <button className="copy" onClick={onCopy}>
                        <FontAwesomeIcon icon="copy" title="Copiar datos"/>
                    </button>
                </div>
                }

                {readOnly &&
                <div className="buttons">
                    <button className="cancel" onClick={onCancel}>Cerrar</button>
                    <div className="stretch"/>
                    {/*{invoiceId &&*/}
                    {/*<a className="download" href={`/api/invoices/${invoiceId}/spreadsheet`}>*/}
                    {/*    <FontAwesomeIcon icon="download" title="Planilla"/>*/}
                    {/*</a>*/}
                    {/*}*/}
                    <button className="copy" onClick={onCopy}>
                        <FontAwesomeIcon icon="copy" title="Copiar datos"/>
                    </button>
                </div>
                }
            </div>
        </div>
    );
}

Invoice.propTypes = {
    legalEntityId: PropTypes.number.isRequired,
    clientId: PropTypes.number.isRequired,
    invoiceId: PropTypes.number,
    closeDialog: PropTypes.func.isRequired,
    modal: PropTypes.func.isRequired,
    onInvoiceDelete: PropTypes.func,
    session: PropTypes.object.isRequired,
    invoiceData: PropTypes.object
};

Invoice.defaultProps = {
    onInvoiceSaved: () => {
    }
};

export { Invoice };
