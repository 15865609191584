import "./Subscription.css";
import { useForm } from "react-hook-form";
import axios from "axios";
import handleError from "../../misc/ErrorHandling";
import { isId } from "../../misc/Values";
import { useEffect, useState } from "react";
import { RESOURCE_SUBSCRIPTIONS } from "../../misc/ReferenceData";
import { formatISO } from 'date-fns';
import DateInput from '../../components/form/DateInput';
import FieldError from '../../components/form/FieldError';
import DescriptionInput from '../../components/form/DescriptionInput';
import AmountInput from '../../components/form/AmountInput';
import { ClientSelectInput } from '../../components/form/ClientSelect';

export function Subscription({ subscriptionId, closeDialog, modal, onSubscriptionSaved, session }) {

    const { canWrite } = session;

    const readOnly = !canWrite(RESOURCE_SUBSCRIPTIONS);

    const { register, handleSubmit, errors, control, setValue } = useForm({
        defaultValues: {
            client: null,
            invoice_period: "",
            fee: 0,
            description: "",
            start_date: formatISO(new Date(), { representation: "date" })
        }
    });

    const [lastUpdated, setLastUpdated] = useState();

    useEffect(() => {
        if (isId(subscriptionId)) {
            axios.get(`/api/subscriptions/${subscriptionId}`)
                .then(response => response.data.data)
                .then(sub => {
                    setValue("client", { id: sub.client_id, name: sub.client_name });
                    setValue("invoice_period", sub.invoice_period);
                    setValue("fee", sub.fee);
                    setValue("description", sub.description);
                    setValue("start_date", sub.start_date);
                    setLastUpdated(sub._updated);
                })
                .catch(error => handleError("Alta/modificación de abono", error, modal));
        }
    }, [setValue, subscriptionId, modal]);

    const onSubmit = form => {
        const data = {
            client_id: form.client.id,
            invoice_period: form.invoice_period,
            fee: form.fee,
            description: form.description,
            start_date: form.start_date
        };
        const save = isId(subscriptionId)
            ? axios.put(`/api/subscriptions/${subscriptionId}`, data)
            : axios.post(`/api/subscriptions`, data);
        save.then(() => {
            onSubscriptionSaved();
            closeDialog()
        })
            .catch(error => handleError("Alta/modificación de abono", error, modal));
    }

    const onCancel = () => {
        closeDialog();
    };

    return (
        <div id="subscription-details-container">
            <form onSubmit={handleSubmit(onSubmit)}>
                <section id="subscription-details" className={"form-grid " + (subscriptionId ? "edit" : "new")}>

                    <label className="form-label" htmlFor="client-select-input">Client</label>
                    <ClientSelectInput
                        control={control}
                        name="client"
                        id="client-select-input"
                        autoFocus={!readOnly}
                        readOnly={readOnly}/>
                    <FieldError id="error-client" errors={errors.client}/>

                    <label className="form-label" htmlFor="invoice_period">Ciclo de Facturación</label>
                    <div className="options-invoice_period options">
                        <label className="radio-label">
                            Mensual
                            <input
                                type="radio"
                                className="form-input"
                                name="invoice_period"
                                value="MONTHLY"
                                autoFocus={!readOnly}
                                disabled={readOnly}
                                ref={register({ required: true })}/>
                            <span/>
                        </label>
                        <label className="radio-label">
                            Semestral
                            <input
                                type="radio"
                                className="form-input"
                                name="invoice_period"
                                value="BIANNUAL"
                                disabled={readOnly}
                                ref={register({ required: true })}/>
                            <span/>
                        </label>
                        <label className="radio-label">
                            Anual
                            <input
                                type="radio"
                                className="form-input"
                                name="invoice_period"
                                value="YEARLY"
                                disabled={readOnly}
                                ref={register({ required: true })}/>
                            <span/>
                        </label>
                    </div>
                    <FieldError id="error-invoice_period" errors={errors.invoice_period}/>

                    <label className="form-label" htmlFor="fee">Importe</label>
                    <AmountInput control={control} name="fee" id="fee" readOnly={readOnly} />
                    <FieldError id="error-fee" errors={errors.fee}/>

                    <label className="form-label" htmlFor="description">Concepto</label>
                    <DescriptionInput name="description" id="description" register={register} readOnly={readOnly} />
                    <FieldError id="error-description" errors={errors.description}/>

                    <label className="form-label" htmlFor="start_date">Fecha alta</label>
                    <DateInput control={control} name="start_date" id="start_date" readOnly={readOnly}/>
                    <FieldError id="error-start_date" errors={errors.start_date}/>

                    {subscriptionId &&
                        <>
                            <label className="form-label">Última modificación</label>
                            <span className="form-input last-updated">{lastUpdated ?? "N/A"}</span>
                            <span/>
                        </>
                    }

                    {!readOnly &&
                    <div className="buttons">
                        <button className="save-subscription">Guardar</button>
                        <button className="cancel-edit" onClick={onCancel}>Cancelar</button>
                    </div>
                    }

                    {readOnly &&
                    <div className="buttons">
                        <button className="cancel-edit" onClick={onCancel}>Cerrar</button>
                    </div>
                    }
                </section>
            </form>
        </div>
    );
}
