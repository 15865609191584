import "./HostingPlan.css";
import { Controller, useForm, useWatch } from "react-hook-form";
import axios from "axios";
import handleError from "../../misc/ErrorHandling";
import { Amount, isId, numberOr } from "../../misc/Values";
import { useEffect } from "react";
import NumberFormat from "react-number-format";
import { useNoCacheAxios } from '../../misc/Net';
import { RESOURCE_HOSTING_PLANS } from "../../misc/ReferenceData";
import FieldError from '../../components/form/FieldError';
import AmountDisplay from '../../components/form/AmountDisplay';
import AmountInput from '../../components/form/AmountInput';

export function HostingPlan({ hostingPlanId, closeDialog, modal, onHostingPlanSaved, session }) {

    const { canWrite } = session;

    const readOnly = !canWrite(RESOURCE_HOSTING_PLANS);

    const [{ data: invoicingParameters, loadingInvoicingParameters }] =
        useNoCacheAxios("/api/system/invoicing-parameters");

    const { register, handleSubmit, errors, control, setValue, getValues } = useForm({
        defaultValues: {
            name: "",
            description: "",
            vm: false,
            disk: "",
            vcpu: "",
            memory: "",
            monthly_price: 0
        }
    });

    const vm = useWatch({ control, name: "vm" });
    const monthlyPrice = useWatch({ control, name: "monthly_price" });

    useEffect(() => {
        if (isId(hostingPlanId)) {
            axios.get(`/api/hosting-plans/${hostingPlanId}`)
                .then(response => response.data.data)
                .then(hostingPlan => {
                    setValue("name", hostingPlan.name);
                    setValue("description", hostingPlan.description);
                    setValue("vm", hostingPlan.vm);
                    setValue("disk", hostingPlan.disk);
                    setValue("vcpu", hostingPlan.vcpu);
                    setValue("memory", hostingPlan.memory);
                    setValue("monthly_price", hostingPlan.monthly_price);
                })
                .catch(error => handleError("Alta/modificación de plan hosting", error, modal));
        }
    }, [setValue, hostingPlanId, modal]);

    const calculatePrice = (value, formHandler) => {
        formHandler(value);
        if (vm && !loadingInvoicingParameters && invoicingParameters) {
            let { disk, vcpu, memory } = getValues(["disk", "vcpu", "memory"]);
            let diskRate = new Amount(invoicingParameters.data.disk_rate).times(numberOr(disk, 0));
            let vcpuRate = new Amount(invoicingParameters.data.vcpu_rate).times(numberOr(vcpu, 0));
            let ramGbRate = new Amount(invoicingParameters.data.ram_gb_rate).times(numberOr(memory, 0));
            setValue("monthly_price", vcpuRate.plus(ramGbRate).plus(diskRate).toNumber());
        }
    };

    const onSubmit = form => {
        const data = {
            name: form.name,
            description: form.description,
            vm: form.vm,
            disk: form.disk,
            vcpu: form.vcpu,
            memory: form.memory,
            monthly_price: form.monthly_price
        };
        const save = isId(hostingPlanId)
            ? axios.put(`/api/hosting-plans/${hostingPlanId}`, data)
            : axios.post(`/api/hosting-plans`, data);
        save.then(() => {
            onHostingPlanSaved();
            closeDialog()
        })
            .catch(error => handleError("Alta/modificación de plan hosting", error, modal));
    }

    const onCancel = () => {
        closeDialog();
    };

    return (
        <div id="hosting-plan-details-container">
            <form onSubmit={handleSubmit(onSubmit)}>
                <section id="hosting-plan-details" className="form-grid ">
                    <label className="form-label" htmlFor="name">Nombre plan</label>
                    <input
                        name="name"
                        id="name"
                        className="form-input"
                        autoFocus={!readOnly}
                        readOnly={readOnly}
                        ref={register({ required: true })}/>
                    <FieldError id="error-name" errors={errors.name}/>

                    <label className="form-label" htmlFor="description">Descripción</label>
                    <input
                        name="description"
                        id="description"
                        className="form-input"
                        readOnly={readOnly}
                        ref={register({ required: true })}/>
                    <FieldError id="error-description" errors={errors.description}/>

                    <label className="form-label" htmlFor="vm">VM</label>
                    <span className="checkbox checkbox-vm">
                        <input
                            name="vm"
                            type="checkbox"
                            id="vm"
                            className="form-input stand-alone"
                            disabled={readOnly}
                            ref={register()}/>
                        <label className="form-label" htmlFor="vm"/>
                    </span>
                    <span/>

                    <label className="form-label" htmlFor="disk">Disco</label>
                    <Controller
                        render={({ onChange, value, name }) => (
                            <NumberFormat
                                allowNegative={false}
                                decimalScale="0"
                                onValueChange={(v) => calculatePrice(v.value, onChange)}
                                value={value}
                                defaultValue={0}
                                isNumericString={true}
                                name={name}
                                id="disk"
                                className="form-input"
                                readOnly={readOnly}/>
                        )}
                        name="disk"
                        control={control}
                        rules={{ required: vm, pattern: /^[0-9]{1,8}$/, valueAsNumber: true }}/>
                    <FieldError id="error-disk" errors={errors.disk}/>

                    <label className="form-label" htmlFor="vcpu">VCPU</label>
                    <Controller
                        render={({ onChange, value, name }) => (
                            <NumberFormat
                                allowNegative={false}
                                decimalScale="0"
                                onValueChange={(v) => calculatePrice(v.value, onChange)}
                                value={value}
                                defaultValue={0}
                                isNumericString={true}
                                name={name}
                                id="vcpu"
                                className="form-input"
                                readOnly={readOnly}/>
                        )}
                        name="vcpu"
                        control={control}
                        rules={{ required: vm, pattern: /^[0-9]{1,8}$/, valueAsNumber: true }}/>
                    <FieldError id="error-vcpu" errors={errors.vcpu}/>

                    <label className="form-label" htmlFor="memory">Memoria</label>
                    <Controller
                        render={({ onChange, value, name }) => (
                            <NumberFormat
                                allowNegative={false}
                                decimalScale="0"
                                onValueChange={(v) => calculatePrice(v.value, onChange)}
                                value={value}
                                defaultValue={0}
                                isNumericString={true}
                                name={name}
                                id="memory"
                                className="form-input"
                                readOnly={readOnly}/>
                        )}
                        name="memory"
                        control={control}
                        rules={{ required: vm, pattern: /^[0-9]{1,8}$/, valueAsNumber: true }}/>
                    <FieldError id="error-memory" errors={errors.memory}/>

                    <label className="form-label" htmlFor="monthly_price">Importe mensual</label>
                    <div className="monthly-price-container">
                        <AmountInput control={control} name="monthly_price" id="monthly_price" readOnly={readOnly} />
                        <label>Anual</label>
                        <AmountDisplay amount={monthlyPrice * 12} prefix="$ "/>
                    </div>
                    <FieldError id="error-monthly_price" errors={errors.monthly_price}/>

                    {!readOnly &&
                    <div className="buttons">
                        <button className="save-hosting-plan">Guardar</button>
                        <button className="cancel-edit" onClick={onCancel}>Cancelar</button>
                    </div>
                    }

                    {readOnly &&
                    <div className="buttons">
                        <button className="cancel-edit" onClick={onCancel}>Cerrar</button>
                    </div>
                    }
                </section>
            </form>
        </div>
    );
}
