import { Decimal } from 'decimal.js';
import es from 'date-fns/locale/es';
import { format, parseISO, set, sub } from 'date-fns';

export const isBlank = a =>
    typeof (a) !== 'string' || a.trim() === '';

export const isNotBlank = a =>
    !isBlank(a)

export const isId = a =>
    typeof (a) === 'number' && a > 0;

export function Amount(amt) {
    return (new Decimal(amt)).toDP(2);
}

export function Percentage(pct) {
    return (new Decimal(pct)).toDP(5);
}

export const isNumber = value =>
    typeof (value) === 'number' && !isNaN(value);

export const numberOr = (value, defaultValue) =>
    isNumber(value) ? value : defaultValue;

export const formatDate = (date, formatStr = 'P') =>
    typeof (date) === 'string'
        ? format(parseISO(date), formatStr, { locale: es })
        : format(date, formatStr, { locale: es });

let now = new Date();
let today = set(now, { date: 1, hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
let startOfThisMonth = set(today, { date: 1 });
let startOfLastMonth = sub(startOfThisMonth, { months: 1 });

export const currentPeriod =
    { month: startOfThisMonth.getMonth() + 1, year: startOfThisMonth.getFullYear() };

export const lastPeriod =
    { month: startOfLastMonth.getMonth() + 1, year: startOfLastMonth.getFullYear() };

export const isOldPeriod = ({ month, year }) =>
    new Date(year, month - 1, 1) < startOfLastMonth;

export const initials = name =>
    name.split(" ").filter(p => p !== "").map(p => p[0]).join("")
