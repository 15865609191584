import "./Work.css";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useModal } from "../../components/Modal";
import { ClientSelectInput } from "../../components/form/ClientSelect";
import DescriptionInput from "../../components/form/DescriptionInput";
import DateInput from "../../components/form/DateInput";
import handleError from "../../misc/ErrorHandling";
import FieldError from "../../components/form/FieldError";
import { formatISO } from "date-fns";
import { useContext, useEffect, useState } from "react";
import { isId } from "../../misc/Values";
import PropTypes from "prop-types";
import { RESOURCE_WORKS } from "../../misc/ReferenceData";
import { SessionContext } from '../../components/Session';

function Work(props) {

    const { workId, closeDialog, modal: parentModal, onWorkSaved, session: parentSession } = props;

    const ownSession = useContext(SessionContext);

    const { canWrite } = parentSession ?? ownSession;

    const readOnly = !canWrite(RESOURCE_WORKS);

    const defaultValues = () => ({
        client: null,
        date: formatISO(new Date(), { representation: "date" }),
        hours: 1,
        description: "",
        immediate_invoice: false
    });

    const { register, handleSubmit, errors, reset, control, setValue } = useForm({ defaultValues: defaultValues() });

    const [work, setWork] = useState();

    const invoiced = work && work.invoice_id;

    const ownModal = useModal();

    const modal = parentModal ?? ownModal;

    const operation = isId(workId) ? "Modificación" : "Alta";

    useEffect(() => {
        if (isId(workId)) {
            axios.get(`/api/works/${workId}`)
                .then(response => response.data.data)
                .then(work => {
                    setValue("client", { id: work.client_id, name: work.client_name });
                    setValue("date", work.date);
                    setValue("hours", work.hours);
                    setValue("description", work.description);
                    setValue("immediate_invoice", !work.monthly_invoice);
                    setWork(work);
                })
                .catch(error => handleError(`${operation} de servicio`, error, modal));
        }
    }, [setValue, workId, modal, operation]);

    const onSubmit = form => {
        const data = {
            client_id: form.client.id,
            date: form.date,
            hours: form.hours,
            description: form.description,
            monthly_invoice: !form.immediate_invoice
        };
        const save = isId(workId)
            ? axios.put(`/api/works/${workId}`, data)
                .then(() => {
                    onWorkSaved();
                    closeDialog();
                })
            : axios.post(`/api/works`, data)
                .then(() => modal({ title: "Servicios", message: "Servicio agregado" }))
                .then(() => reset(defaultValues()));
        save.catch(error => handleError(`${operation} de servicio`, error, modal));
    };

    const onCancel = () => {
        closeDialog();
    };

    return (
        <div id="work-container">
            {!workId &&
            <div id="work-title-container" className="form-grid">
                <h2 id="work-title">Carga De Servicio</h2>
            </div>
            }
            <div id="work-details-container">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <section id="work-details" className="form-grid">
                        <label className="form-label" htmlFor="date">Fecha</label>
                        <DateInput control={control} name="date" id="date" readOnly={readOnly}/>
                        <FieldError id="error-date" errors={errors.date}/>
                        <label className="form-label" htmlFor="client-select">Cliente</label>
                        <ClientSelectInput
                            control={control}
                            name="client"
                            id="client-select"
                            autoFocus={!readOnly}
                            readOnly={readOnly}/>
                        <FieldError id="error-client" errors={errors.client}/>
                        <label className="form-label" htmlFor="hours">Horas</label>
                        {/*TODO usar "number" solo en mobile, mover a componente*/}
                        <input type="number"
                               id="hours"
                               name="hours"
                               className="form-input"
                               min={1}
                               max={24}
                               readOnly={readOnly}
                               ref={register({ required: true, min: 1, max: 24 })}/>
                        <FieldError id="error-hours" errors={errors.hours}/>
                        <label className="form-label" htmlFor="description">Concepto</label>
                        <DescriptionInput id="description" name="description" register={register} readOnly={readOnly}/>
                        <FieldError id="error-description" errors={errors.description}/>
                        <label className="form-label" htmlFor="immediate_invoice">Facturación inmediata</label>
                        <span className="checkbox checkbox-immediate_invoice">
                            <input
                                name="immediate_invoice"
                                type="checkbox"
                                id="immediate_invoice"
                                className="form-input stand-alone"
                                disabled={readOnly}
                                ref={register()}/>
                            <label className="form-label" htmlFor="immediate_invoice"/>
                        </span>
                        <span/>

                        {!readOnly &&
                        <div className="buttons">
                            <button
                                className="save-work"
                                disabled={(isId(workId) && !work) || invoiced}
                                title={invoiced && "Ya facturado"}>
                                Guardar
                            </button>
                            {isId(workId) && <button className="cancel" onClick={onCancel}>Cancelar</button>}
                        </div>
                        }

                        {readOnly &&
                        <div className="buttons">
                            <button className="cancel" onClick={onCancel}>Cerrar</button>
                        </div>
                        }
                    </section>
                </form>
            </div>
        </div>
    );
}

Work.propTypes = {
    workId: PropTypes.number,
    closeDialog: PropTypes.func,
    modal: PropTypes.func,
    onWorkSaved: PropTypes.func
};

Work.defaultProps = {
    workId: null,
    closeDialog: () => {
    },
    modal: null,
    onWorkSaved: () => {
    }
};

export default Work;
