import "./Clients.css";
import axios from "axios";
import { useModal } from "../../components/Modal";
import Command from "../../components/Command";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import handleError from "../../misc/ErrorHandling";
import NumberFormat from "react-number-format";
import Confirm from "../../components/Confirm";
import { useContext } from "react";
import { SessionContext } from "../../components/Session";
import { RESOURCE_CLIENTS } from "../../misc/ReferenceData";
import { Client } from "./Client";
import { useNoCacheAxios } from '../../misc/Net';

function Clients() {

    const session = useContext(SessionContext);

    const { canWrite } = session;

    const readOnly = !canWrite(RESOURCE_CLIENTS);

    const modal = useModal();

    const [{ data: clients, loading }, getClients] = useNoCacheAxios(`/api/clients`);

    const onGetClients = () =>
        getClients()
            .catch(error => handleError("Clientes", error, modal));

    const onClientSaved = onGetClients;

    const onNewClient = () =>
        modal({
            title: "Alta cliente",
            style: "dialog",
            component: onClose =>
                <Client closeDialog={onClose}
                         modal={modal}
                         onClientSaved={onClientSaved}
                         session={session}/>
        });

    const onClientSelect = (clientId) => () =>
        modal({
            title: readOnly ? "Detalle cliente" : "Modificación cliente",
            style: "dialog",
            component: onClose =>
                <Client clientId={clientId}
                        closeDialog={onClose}
                        modal={modal}
                        onClientSaved={onClientSaved}
                        session={session}/>
        });

    const onClientDelete = (clientId, clientName) => () =>
        modal({
            title: "Baja de cliente",
            style: "dialog",
            component: onClose =>
                <Confirm message={`Se procederá a la baja de ${clientName}`}
                      closeDialog={onClose}
                      modal={modal}
                      onConfirm={proceedWithDelete(clientId)}/>
        });

    const proceedWithDelete = clientId => () =>
        axios.delete(`/api/clients/${clientId}`)
            .then(() => getClients())
            .catch(error => handleError("Baja de cliente", error, modal));

    let rows;
    if (loading || !clients)
        rows = (
            <div className="table-row">
                <span className="table-cell-whole-row">Cargando...</span>
            </div>
        );
    else if (clients.data.length === 0)
        rows = (
            <div className="table-row">
                <span className="table-cell-whole-row">No hay clients</span>
            </div>
        );
    else
        rows = clients.data.map(client => {
            return (
                <div key={client.id} className="table-row">
                    <span className="table-cell clickable" onClick={onClientSelect(client.id)}>{client.name}</span>
                    <span className="table-cell clickable" onClick={onClientSelect(client.id)}>
                        <abbr title={client.iva_name}>{client.iva_code}</abbr>
                    </span>
                    <span className="table-cell clickable" onClick={onClientSelect(client.id)}>
                        {client.cuit === 0
                        ? "—"
                        : <NumberFormat
                                displayType="text"
                                value={client.cuit}
                                format="##-########-#"/>
                        }
                    </span>
                    <span className="table-cell clickable" onClick={onClientSelect(client.id)}>{client.contact}</span>
                    <span className="table-cell client-emails-column">
                        <a className="email first-email" href={`mailto:${client.emails[0]}`}>{client.emails[0]}</a>
                        {client.emails.length > 1 &&
                        <div className="other-emails has-tooltip">
                            <span><FontAwesomeIcon icon="list-alt"/>️</span>
                            <span className="tooltip-text">
                                <ul>
                                {client.emails.map(email => (
                                    <li>
                                        <a className="email" href={`mailto:${email}`}>{email}</a>
                                    </li>
                                ))}
                                </ul>
                            </span>
                        </div>
                        }
                    </span>
                    <span className="table-cell clickable" onClick={onClientSelect(client.id)}>{client.address}</span>
                    <span className="table-cell">
                        <Command onClick={onClientDelete(client.id, client.name)} disabled={readOnly}>
                            <FontAwesomeIcon icon="trash-alt"/>
                        </Command>
                    </span>
                </div>
            );
        });

    return (
        <>
            <div id="clients-container">
                <div id="clients-title-container" className="form-grid">
                    <h2 id="clients-title">
                        Listado De Clientes
                        <Command onClick={onGetClients}><FontAwesomeIcon icon="sync-alt"/></Command>
                    </h2>
                </div>
                <div id="clients">
                    <div className="table-row">
                        <span className="table-header">Cliente</span>
                        <span className="table-header">IVA</span>
                        <span className="table-header">CUIT</span>
                        <span className="table-header">Contacto</span>
                        <span className="table-header">E-mail</span>
                        <span className="table-header">Dirección</span>
                        <span className="table-header">Eliminar</span>
                    </div>
                    {rows}
                </div>

            </div>
            <div className="operations">
                {!readOnly &&
                <button onClick={onNewClient}>Nuevo Cliente</button>
                }
            </div>
        </>
    );
}

export default Clients;
