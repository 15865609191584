import { reactSelectStyle } from "../../misc/ReactSelectStyle";
import PropTypes from "prop-types";
import { useNoCacheAxios } from '../../misc/Net';
import Select from "react-select";
import { Controller } from 'react-hook-form';

function CellTaxSelect(props) {

    const { id, name, control, className, defaultLabel, autoFocus, onChange: parentOnChange } = props;

    const [{ data, loading }] = useNoCacheAxios("/api/taxes");

    return (
        <Controller
            render={({ onChange: controllerOnChange, value }) => (
                <Select
                    className={className + " cell-react-select"}
                    inputId={id}
                    styles={reactSelectStyle}
                    value={value}
                    options={loading ? [] : data.data}
                    getOptionLabel={tax => tax.name}
                    getOptionValue={tax => tax}
                    placeholder={defaultLabel ?? "Seleccione impuesto"}
                    autoFocus={autoFocus}
                    menuPortalTarget={document.body}
                    menuPosition="fixed"
                    onChange={v => {
                        controllerOnChange(v);
                        parentOnChange(v);
                    }}
                />
            )}
            defaultValue={null}
            id={id}
            name={name}
            control={control}
            rules={{ required: true }}
        />
    );
}

CellTaxSelect.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    control: PropTypes.object.isRequired,
    className: PropTypes.string,
    defaultLabel: PropTypes.string,
    value: PropTypes.any,
    setValue: PropTypes.func,
    onChange: PropTypes.func,
    autoFocus: PropTypes.bool
};

CellTaxSelect.defaultProps = {
    className: "cell-tax-select",
    onChange: () => {
    },
    autoFocus: false
};

export { CellTaxSelect };
