import { months } from '../../misc/ReferenceData';
import { tableStyles } from './Common';

export function InvoiceHeader({ client, iva, invoice, legalEntity }) {

    return (
        <table style={tableStyles.table}>
            <tbody>
            <tr>
                <td style={tableStyles.cellInvoiceHeader}>Cliente</td>
                <td style={tableStyles.cellInvoiceHeaderData}>{client?.data.name ?? "..."}</td>
            </tr>
            <tr>
                <td style={tableStyles.cellInvoiceHeader}>Condición IVA</td>
                <td style={tableStyles.cellInvoiceHeaderData}>{iva?.data.name ?? "..."}</td>
            </tr>
            <tr>
                <td style={tableStyles.cellInvoiceHeader}>CUIT cliente</td>
                <td style={tableStyles.cellInvoiceHeaderData}>{client?.data.cuit ?? "..."}</td>
            </tr>
            <tr>
                <td style={tableStyles.cellInvoiceHeader}>Contacto</td>
                <td style={tableStyles.cellInvoiceHeaderData}>{client?.data.contact ?? "..."}</td>
            </tr>
            <tr>
                <td style={tableStyles.cellInvoiceHeader}>E-mail</td>
                <td style={tableStyles.cellInvoiceHeader}>
                    <ul style={tableStyles.emailList}>
                        {client?.data.emails && client.data.emails.map(email => (
                            <li key={email}>
                                <a className="email" href={`mailto:${email}`}>{email}</a>
                            </li>
                        ))}
                    </ul>
                </td>
            </tr>
            <tr>
                <td style={tableStyles.cellInvoiceHeader}>Dirección</td>
                <td style={tableStyles.cellInvoiceHeaderData}>{client?.data.address ?? "..."}</td>
            </tr>
            <tr>
                <td style={tableStyles.cellInvoiceHeader}>Período</td>
                <td style={tableStyles.cellInvoiceHeaderData}>
                    {invoice?.data.month ? months.find(month => month.value === invoice.data.month).label : "..."}
                    &nbsp;
                    {invoice?.data.year}
                </td>
            </tr>
            <tr>
                <td style={tableStyles.cellInvoiceHeader}>Emisor de factura</td>
                <td style={tableStyles.cellInvoiceHeaderData}>{legalEntity?.data.name ?? "..."}</td>
            </tr>
            <tr>
                <td style={tableStyles.cellInvoiceHeader}>CUIT emisor</td>
                <td style={tableStyles.cellInvoiceHeaderData}>{legalEntity?.data.cuit ?? "..."}</td>
            </tr>
            </tbody>
        </table>
    );
}
